import axios from 'axios'
import {BodyAreaInterface} from './_models'
import {Page} from "../generic/_models";

export const CRUD_BODY_AREA_URL = `${process.env.REACT_APP_API_PATH}/body_areas`


export function getBodyAreas(
  params: {} = {},
  id?: number
) {
  let url = id ? `${CRUD_BODY_AREA_URL}/${id}/` : CRUD_BODY_AREA_URL;
  return axios.get<Page<BodyAreaInterface>>(`${url}/`, { params: params});
}

export function addBodyArea(bodyArea: BodyAreaInterface) {
  return axios.post<BodyAreaInterface>(`${CRUD_BODY_AREA_URL}/`, bodyArea)
}

export function updateBodyArea(bodyArea: BodyAreaInterface) {
  return axios.put<BodyAreaInterface>(`${CRUD_BODY_AREA_URL}/`, bodyArea)
}

export function deleteBodyArea(id: number) {
  return axios.delete<BodyAreaInterface>(`${CRUD_BODY_AREA_URL}/${id}/`)
}
