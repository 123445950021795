/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {PersonModel} from "../_models";

type Props = {
  persons: PersonModel[]
}

const PersonMatchesTable: FC<Props> = ({persons})  => {

  if (persons.length > 0) {
    return <table className="table">
      <thead className="fw-bold text-muted bg-light">
      <tr>
        <th scope="col">First name</th>
        <th scope="col">Last name</th>
        <th scope="col">Phone</th>
        <th scope="col">Birthday</th>
      </tr>
      </thead>
      <tbody>
      {persons.map((match => {
        return <tr>
          <td>{match.first_name}</td>
          <td>{match.last_name}</td>
          <td>{match.phone}</td>
          <td>{match.birthdate}</td>
        </tr>;
      }))}
      </tbody>
    </table>;
  } else {
    return <></>
  }
}

export {PersonMatchesTable}
