import {useIntl} from 'react-intl';
import {PageTitle} from '../../../_metronic/layout/core';
import {WithdrawsTable} from "../../modules/withdraws/tables/WithdrawsTable";

const WithdrawsPage = () => {
  return <>
    {/* begin::Row */}
    <div className='row g-5 g-xl-8'>
      <WithdrawsTable className="mb-5 mb-xl-8"/>
    </div>
    {/* end::Row */}
  </>;
}

const WithdrawsWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'GENERAL.WITHDRAWS'})}</PageTitle>
      <WithdrawsPage />
    </>
  )
}

export {WithdrawsWrapper}
