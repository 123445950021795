/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {CardTypeModel} from "../_models";
import {get_card_types} from "../_requests";
import {CardTypeRow} from "../components/CardTypeRow";
import {AddCardTypeModal} from "../modals/AddCardTypeModal";
import {Page} from "../../generic/_models";

type Props = {
  className: string,
}

const CardTypesTable: React.FC<Props> = ({className}) => {
  let [cardTypes, setCardTypes] = useState<Page<CardTypeModel>>(new Page<CardTypeModel>());

  const refreshTable = async () => {
    const requestApps = async () => {
      try {
        const {data} = await get_card_types();
        setCardTypes(data);
      } catch (error) {
        console.log(error);
      }
    }
    requestApps().then();
  }

  useEffect(() => {
    refreshTable().then();
  }, []);

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Card types</span>
        </h3>
        <div className='card-toolbar'>
          {/* begin::Menu */}
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-bs-toggle='modal'
            data-bs-target='#modal_add_card_type'
          >
            <KTSVG path='/media/icons/duotune/general/gen035.svg' className='svg-icon-2' />
          </button>
          {/* begin::Menu 1 */}
          <AddCardTypeModal callBack={refreshTable}/>
          {/* end::Menu 1 */}
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-5'>
            {/* begin::Table head */}
            <thead>
              <tr>
                <th className='p-0 w-50px'/>
                <th className='p-0 min-w-200px'/>
                <th className='p-0 min-w-100px'/>
                <th className='p-0 min-w-40px'/>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {cardTypes.items.map(cardType => {
                return <CardTypeRow cardType={cardType}/>;
              })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
    </div>
  )
}

export {CardTypesTable}
