/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react'
import moment from "moment";
import {KTSVG} from '../../../../_metronic/helpers'
import {addExpense} from "../_requests";
import {ExpenseModel} from "../_models";
import {handleChangeNumber, handleChangeString} from "../../generic/helpers/changeHandlers";

type Props = {
  callBack: Function,
}

const AddExpenseModal: FC<Props> = ({callBack})  => {
  let [description, setDescription] = useState("");
  let [amount, setAmount] = useState(0);
  let [createdAt, setCreatedAt] = useState(moment(new Date()).format("YYYY-MM-DD"));

  async function createHandler(_: any) {
    let ex = new ExpenseModel()
    ex.description = description;
    ex.amount = amount;
    ex.createdAt = createdAt;

    const resp = await addExpense(ex.asCreateInterface());
    await callBack();

    if (resp.status !== 202) {
      //TODO: Display message
    }

    await clearFields();
  }

  const handleCreatedAt = (e: { target: { value: string | number | Date; }; }) => {
    const newDate = moment(new Date(e.target.value)).format('YYYY-MM-DD');
    setCreatedAt(newDate);
  };

  async function clearFields() {
    await setDescription("");
    await setAmount(0);
    let date = new Date().toISOString().substring(0,10);
    await setCreatedAt(date);
  }

  return (
    <div className='modal fade' id='modal_add_expense' aria-hidden='true'>
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
          </div>

          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>Add New Expense</h1>
            </div>

            <input
              type="text"
              className="form-control mb-5"
              placeholder="Description"
              aria-label="Description"
              aria-describedby="basic-addon1"
              onChange={(e) => handleChangeString(e, setDescription)}
            />

            <input
              type="text"
              className="form-control mb-5"
              placeholder="Amount"
              aria-label="Amount"
              aria-describedby="basic-addon1"
              onChange={(e) => handleChangeNumber(e, setAmount)}
            />

            <input
              type="date"
              className="form-control mb-5"
              placeholder="Created at"
              aria-label="Created at"
              aria-describedby="basic-addon1"
              value={createdAt}
              onChange={handleCreatedAt}
            />

            <div
              className='btn btn-light-success fw-bolder w-100 mb-8'
              data-bs-dismiss='modal'
              onClick={createHandler}
            >Add</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {AddExpenseModal}
