/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState, useRef} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {ExtraFieldGroup, ProcedureModel, ProcedureUpdateInterface} from "../_models";
import {EditText} from "react-edit-text";
import {handleChangeString} from "../../generic/helpers/changeHandlers";
import {delete_procedure, update_procedure} from "../_requests";
import Select from "react-select";
import {buildLabelForExtraParameter} from "../../services/extraParameter";

type Option = {
  "label": string,
  "value": string
};

type Props = {
  procedure: ProcedureModel,
  callback: Function
  extraParameters: ExtraFieldGroup[],
  refreshExtraParameters: Function,
}

const ProcedureRow: React.FC<Props> = ({
    procedure,
    callback,
    extraParameters,
    refreshExtraParameters
  }) => {
  let [title, setTitle] = useState(procedure.title);
  let [extraParametersAsOptions, setExtraParametersAsOptions] = useState<Option[]>([]);
  let selectedExtraParameters = useRef<ExtraFieldGroup | undefined >(procedure.fields);
  let [defaultExtraParameters, setDefaultExtraParameters] = useState<Option[]>([]);

  const handleSave = async (updateField: string) => {
    let updatedProcedure = {
      "id": procedure.id,
    } as ProcedureUpdateInterface;

    if (updateField === "title") {
      updatedProcedure["title"] = title;
    }

    if (updateField === "fields") {
      updatedProcedure["fields"] = selectedExtraParameters.current;
    }

    const resp = await update_procedure(updatedProcedure);
    await callback();

    if (resp.status !== 202) {
      console.log(resp.statusText);
    }
  };

  const handleDelete = async () => {
    if (procedure.id) {
      const resp = await delete_procedure(procedure.id);
      await callback();

      if (resp.status !== 202) {
        console.log(resp.statusText);
      }
    }
  };

  const currentFieldGroup = () => {
    let index = extraParameters.findIndex((fieldGroup) => {
      return (procedure.fields && fieldGroup.id === procedure.fields.id);
    })

    if (procedure.fields) {
      let newOption: Option = {
        "label": procedure.fields.label,
        "value": index.toString()
      }

      return newOption;
    }
  }

  useEffect(() => {
    const transformExtraParametersAsOptions = (extraParameters: ExtraFieldGroup[]) => {
      let newOptions: Option[] = [];

      extraParameters.map((parameter, index) => {
        let newOption: Option = {
          "label": `${buildLabelForExtraParameter(parameter.fields)}`,
          "value": index.toString()
        }

        newOptions.push(newOption);
        return true;
      });

      return newOptions;
    };

    setExtraParametersAsOptions(transformExtraParametersAsOptions(extraParameters));
    setDefaultExtraParameters(extraParametersAsOptions.filter((p) => {
      let labels: string[] = [];

      if (procedure.fields) {
          labels.push(procedure.fields.label);
          return true;
      }

      return labels.includes(p.label);
    }));
  }, [extraParametersAsOptions, extraParameters, procedure]);

  return (
    <tr>
      <td>
        <div className='d-flex align-items-center'>
          <div className='d-flex justify-content-start flex-column'>
            <EditText
              className='text-dark fw-bold text-hover-primary mb-1 fs-6'
              value={title}
              onChange={(e) => handleChangeString(e, setTitle)}
              onSave={() => { handleSave("title").then(); }}
            />
          </div>
        </div>
      </td>
      <td>
        <div className="d-flex flex-row">
          <Select
            className='mb-3 w-50'
            data-kt-select2='true'
            data-placeholder='Select Extra Fields'
            data-allow-clear='true'
            defaultValue={defaultExtraParameters}
            value={currentFieldGroup()}
            isMulti={false}
            isSearchable={false}
            options={extraParametersAsOptions}
            // @ts-ignore
            onChange={(newValue: Option) => {
              selectedExtraParameters.current = extraParameters[+newValue.value];
              handleSave("fields").then();
            }}
          />

          <button
            className='btn btn-icon btn-bg-light btn-active-color-primary ms-3'
            onClick={() => {refreshExtraParameters()}}
          >
            <KTSVG path='/media/icons/update.svg' className='svg-icon-3' />
          </button>
        </div>
      </td>
      <td className='text-end'>
        <button
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
          onClick={handleDelete}
        >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </button>
      </td>
    </tr>
  )
}

export {ProcedureRow}
