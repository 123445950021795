import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {ExpensesTable} from "../../modules/expenses/tables/ExpensesTable";

const ExpensesPage = () => {
  return <>
    {/* begin::Row */}
    <div className='row g-5 g-xl-8'>
      <ExpensesTable className="mb-5 mb-xl-8"/>
    </div>
    {/* end::Row */}
  </>;
}

const ExpensesWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'GENERAL.EXPENSES'})}</PageTitle>
      <ExpensesPage />
    </>
  )
}

export {ExpensesWrapper}
