import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {ServicesTable} from "../../modules/services/tables/ServicesTable";


const DashboardPage = () => {

  return <>
    {/* begin::Row */}
    <div className='row g-5 g-xl-8'>
      <ServicesTable className=""/>
    </div>
    {/* end::Row */}
  </>;
}

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.MARKETPLACE'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
