/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useCallback, useEffect, useState} from 'react'
import moment from "moment";
import {KTSVG} from '../../../../_metronic/helpers'
import {AddServiceModal} from "../modals/AddServiceModal";
import {ServiceInterface, ServiceModel, serviceModelFromInterface, ServiceParams} from "../_models/service";
import {get_services} from "../_requests/service";
import {ServiceRow} from "../components/ServiceRow";
import {ServiceDaySummary} from "../components/ServiceDaySummary";
import {Page} from "../../generic/_models";

type Props = {
  className: string
}

const ServicesTable: React.FC<Props> = ({className}) => {
  let [selectedService, setSelectedService] = useState<ServiceModel>(new ServiceModel());
  let [services, setServices] = useState<Page<ServiceInterface>>(new Page<ServiceInterface>());
  let [queryParams, setQueryParams] = useState<ServiceParams>(new ServiceParams());
  let [summaryRefresher, setSummaryRefresher] = useState<number>(0);

  const requestServices = async (params: ServiceParams) => {
    try {
      const {data} = await get_services(params);
      setServices(data);
    } catch (error) {
      console.log(error);
    }
  }

  const refreshTable = useCallback(async () => {
    requestServices(queryParams).then();
  }, [queryParams]);

  useEffect(() => {
    refreshTable().then();
  }, [refreshTable]);

  return (
    <>
      <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <div className="d-flex flex-row">
            <div className='d-flex flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>Services</span>
              <span className='text-muted mt-1 fw-semibold fs-7'>Daily provided services</span>
            </div>
            <input
              type="date"
              className="form-control mb-5 ms-3"
              placeholder="Date"
              aria-label="Date"
              aria-describedby="basic-addon1"
              value={queryParams.created_at}
              onChange={(e) => {
                const newDate = moment(new Date(e.target.value)).format('YYYY-MM-DD');
                var params = new ServiceParams();
                params.created_at = newDate;
                params.client_id = queryParams.client_id;
                params.procedure_id = queryParams.procedure_id;
                params.operator_id = queryParams.operator_id;
                params.correction = queryParams.correction;
                params.limit = queryParams.limit;
                params.offset = queryParams.offset;
                params.page = queryParams.page;
                params.modified_at = queryParams.modified_at;
                setQueryParams(params);
              }}
            />
          </div>
        </h3>
        <div className='card-toolbar'>
          {/* begin::Menu */}
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-bs-toggle='modal'
            data-bs-target='#modal_add_service'
          >
            <KTSVG path='/media/icons/duotune/general/gen035.svg' className='svg-icon-2' />
          </button>
          {/* begin::Menu 1 */}
          <AddServiceModal refreshServices={refreshTable} refreshSummary={setSummaryRefresher} />
          {/*<EditServiceModal callBack={refreshTable} service={selectedService}/>*/}
          {/* end::Menu 1 */}
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-50px rounded-start'>Name</th>
                <th className='min-w-50px'>Procedure</th>
                <th className='min-w-50px'>Body areas</th>
                <th className='min-w-50px'>Session</th>
                <th className='min-w-30px'>Correction</th>
                <th className='min-w-50px'>Bonus Card</th>
                <th className='min-w-50px'>Extra</th>
                <th className='min-w-50px'>Payment</th>
                <th className='min-w-100px text-end rounded-end'></th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
            {services.items.map(service => {
              return <ServiceRow
                service={serviceModelFromInterface(service)}
                refreshServices={refreshTable}
                selectCallBack={setSelectedService}
              />
            })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
      <ServiceDaySummary date={queryParams.created_at} refresher={summaryRefresher}/>
    </>
  )
}

export {ServicesTable}
