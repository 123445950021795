const handleChangeString = (e: { target: { value: any; }; }, setFn: (arg0: any) => void) => {
  setFn(e.target.value ? e.target.value : "");
};

const handleChangeNumber = (e: { target: { value: any; }; }, setFn: (arg0: any) => void) => {
  setFn(Number(e.target.value ? e.target.value : "0"));
};

const handleChangeBoolean = async (e: { target: { checked: any; }; }, setFn: (arg0: any) => void) => {
  await setFn(Boolean(e.target.checked ? e.target.checked : false));
};

const handleChangeDatetime = (e: Date | null, setFn: (arg0: any) => void) => {
  setFn(e ? e : new Date());
};

export {
  handleChangeString,
  handleChangeNumber,
  handleChangeBoolean,
  handleChangeDatetime
}
