/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState, useCallback, useRef} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {AddPersonModal} from "../modals/AddPersonModal";
import {PersonQueryParamsModel, PersonModel} from "../_models";
import {getPersons} from "../_requests";
import {PersonRow} from "../components/PersonRow";
import {Page} from "../../generic/_models";
import {Paginator} from "../../generic/paginator/Paginator";
import {handleChangeString} from "../../generic/helpers/changeHandlers";

type Props = {
  className: string
}

const PersonsTable: React.FC<Props> = ({className}) => {
  let refreshCount = useRef(0);
  let [persons, setPersons] = useState<Page<PersonModel>>(new Page<PersonModel>());
  let [requestParams, setRequestParams] = useState<PersonQueryParamsModel>(new PersonQueryParamsModel());
  let [timeoutId, setTimeoutId] = useState<any>(0);

  const requestPersons = async (params: PersonQueryParamsModel) => {
    try {
      await setPersons(new Page<PersonModel>());
      const {data} = await getPersons(params.extract());
      await setPersons(data);
      refreshCount.current += 1;
    } catch (error) {
      console.log(error);
    }
  }

  const refreshTable = useCallback(() => {
    requestPersons(requestParams).then();
  }, [requestParams]);

  useEffect(() => {
    if (refreshCount.current === 0) {
      refreshTable();
    }
  }, [refreshTable, refreshCount]);

  const pageChange = (page_num: number) => {
    let new_params = requestParams;
    new_params.page = page_num - 1;
    requestPersons(new_params).then();
    setRequestParams(new_params);
  };

  const handlePageSize = (e: { target: { value: any; }; }) => {
    const page_size = Number(e.target.value);
    let new_params = requestParams;
    new_params.limit = page_size;
    requestPersons(new_params).then();
    setRequestParams(new_params);
  };

  const startTimerForPersonRequest = () => {
    clearTimeout(timeoutId);
    const tmp_timeoutId = setTimeout(() => {
      refreshTable();
    }, 1000);
    setTimeoutId(tmp_timeoutId);
  };

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Persons</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>The list of awesome people using this service</span>
        </h3>
        <div className='card-toolbar'>
          {/* begin::Menu */}
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-bs-toggle='modal'
            data-bs-target='#modal_add_person'
          >
            <KTSVG path='/media/icons/duotune/general/gen035.svg' className='svg-icon-2' />
          </button>
          {/* begin::Menu 1 */}
          <AddPersonModal callBack={refreshTable}/>
          {/* end::Menu 1 */}
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        <div className="d-flex flex-row gap-2 mb-6">
          <input
            type="text"
            className="form-control"
            placeholder="Search"
            aria-label="Search"
            aria-describedby="basic-addon1"
            value={requestParams.search}
            onChange={(e) => {
              requestParams.search = e.target.value ? e.target.value : "";
              setRequestParams(requestParams);
            }}
            onKeyUp={() => {startTimerForPersonRequest()}}
          />
        </div>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-50px rounded-start'>First Name</th>
                <th className='min-w-50px'>Last Name</th>
                <th className='min-w-50px'>Phone</th>
                <th className='min-w-50px'>Staff</th>
                <th className='min-w-50px'>Birthday</th>
                <th className='min-w-50px text-end rounded-end'></th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
            {persons.items.map(person => {
              return <PersonRow person={person} callback={refreshTable}/>
            })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>

        <Paginator
          pageChangeCallback={pageChange}
          pageSizeCallback={handlePageSize}
          current_page={persons.current_page}
          max_pages={persons.max_pages}
          pageSizes={[10, 20, 50, 100]}
        />
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {PersonsTable}
