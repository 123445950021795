import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import {CardsWrapper} from "../pages/cards/CardsWrapper";
import {CardTypesWrapper} from "../pages/cards/CardTypesWrapper";
import {CardTransactionsWrapper} from "../pages/cards/CardTransactionsWrapper";
import {DashboardWrapper} from "../pages/dashboard/DashboardWrapper";
import {PersonsWrapper} from "../pages/persons/PersonsWrapper";
import {ProceduresWrapper} from "../pages/procedures/ProcedureWrapper";
import {BodyAreasWrapper} from "../pages/body_areas/BodyAreasWrapper";
import {ExpensesWrapper} from "../pages/expenses/ExpensesWrapper";
import {WithdrawsWrapper} from "../pages/withdraws/WithdrawsWrapper";

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/generic/profile/ProfilePage'))
  const AccountPage = lazy(() => import('../modules/generic/accounts/AccountPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='person' element={<PersonsWrapper />} />
        <Route path='procedure/procedures' element={<ProceduresWrapper />} />
        <Route path='procedure/body_areas' element={<BodyAreasWrapper />} />
        <Route path='expenses' element={<ExpensesWrapper />} />
        <Route path='withdraws' element={<WithdrawsWrapper />} />
        <Route path='bonus-card/cards' element={<CardsWrapper />} />
        <Route path='bonus-card/types' element={<CardTypesWrapper />} />
        <Route path='bonus-card/transactions' element={<CardTransactionsWrapper />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
