export function getCurrentUrl(pathname: string) {
  const path_list = pathname.split(/[?#]/)
  return path_list[path_list.length - 1]
}

export function checkIsActive(pathname: string, url: string) {
  const current = getCurrentUrl(pathname)
  if (!current || !url) {
    return false
  }

  if (current === url) {
    return true
  }

  if (current.indexOf(url) > -1) {
    return true
  }

  return false
}
