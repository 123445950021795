import {AuthModel} from './_models'
import Cookies from 'universal-cookie';

const cookies = new Cookies()

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const cookie_val = cookies.get(AUTH_LOCAL_STORAGE_KEY);
  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)

  if (!lsValue && !cookie_val) {
    return
  }

  try {
    let auth: AuthModel | null = null;

    if (cookie_val) {
      auth = cookie_val as AuthModel;
    } else {
      if (lsValue) {
        auth = JSON.parse(lsValue) as AuthModel;
      }
    }

    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  const cookie_domain = `.${window.location.hostname}`;
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue);
    cookies.set(AUTH_LOCAL_STORAGE_KEY, lsValue, {domain: cookie_domain});
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error);
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
    cookies.remove(AUTH_LOCAL_STORAGE_KEY);
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: {headers: {Authorization: string}}) => {
      const auth = getAuth()
      if (auth && auth.access_token) {
        config.headers.Authorization = `Bearer ${auth.access_token}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
}

export {getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY}
