/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react';

type Props = {
  current_page: number,
  max_pages: number,
  pageChangeCallback: Function,
  pageSizeCallback: Function,
  pageSizes: number[]
}

const Paginator: FC<Props> = ({
  current_page,
  max_pages,
  pageChangeCallback,
  pageSizeCallback,
  pageSizes
}) => {
  let [_current_page, set_CurrentPage] = useState("1");
  let pages = [];

  const handleKeyUp = (event: { key: string; target: any; }) => {
    if (event.key === 'Enter') {
      pageChangeCallback(Number(_current_page))
    }
  };

  const separator = () => {
    return <li className={`page-item`}>
      <button
        className='page-link'
        disabled={true}
      >
        ...
      </button>
    </li>;
  };

  const page_button = (index: number) => {
    if (current_page + 1 === index) {
      return <li className={`page-item active`}>
        <span
          contentEditable="true"
          className='page-link'
          onInput={(e) => {
            set_CurrentPage(e.currentTarget.textContent ? e.currentTarget.textContent : "1");
          }}
          onKeyUp={handleKeyUp}
        >{index}</span>
      </li>
    } else {
      return <li className={`page-item`}>
        <button
          className='page-link'
          onClick={() => {pageChangeCallback(index)}}
        >
          {index}
        </button>
      </li>
    }
  };

  const calculate_middle_pages_start = () => {
    if (current_page === 3) {
      return current_page + 1;
    } else if (current_page === 4) {
      return current_page;
    } else {
      return current_page - 1;
    }
  };

  const calculate_middle_pages_end = () => {
    if (current_page === max_pages - 2) {
      return current_page - 1;
    } else if (current_page === max_pages - 3) {
      return current_page;
    } else if (current_page === max_pages - 4) {
      return current_page + 1;
    } else if (current_page === max_pages - 4){
      return current_page + 2;
    } else if (current_page + 2 < max_pages - 3){
      return current_page + 3;
    } else {
      return max_pages - 3;
    }
  };

  if (max_pages >= 15) {
    // First 3 pages
    for (let i = 1; i <= 3; i++) {
      pages.push(page_button(i));
    }

    if (current_page - 2 > 3) {
      pages.push(separator());
    }

    // Middle pages
    if (current_page + 1 > 3) {
      let start_pages = calculate_middle_pages_start();
      let end_pages = calculate_middle_pages_end();

      for (let i = start_pages; i <= end_pages; i++) {
        pages.push(page_button(i));
      }
    }

    if (current_page + 2 < max_pages - 3) {
      pages.push(separator());
    }

    // Last 3 pages
    for (let i = max_pages - 2; i <= max_pages; i++) {
      pages.push(page_button(i));
    }
  } else {
    for (let i = 1; i <= max_pages; i++) {
      pages.push(page_button(i));
    }
  }

  return <span className="d-flex flex-row justify-content-center">
    <select
      className='form-select me-6 h-35px pt-2 min-w-75px mw-85px'
      data-kt-select2='true'
      data-placeholder='Page Size'
      data-allow-clear='true'
      defaultValue={'10'}
      onChange={(e) => pageSizeCallback(e)}
    >
      {pageSizes.map((pageSize) => {
        return <option value={pageSize}>{pageSize}</option>
      })}
    </select>

    <ul className='pagination'>
      {current_page > 0 ? <li className='page-item previous'>
        <button
          className='page-link'
          onClick={() => {pageChangeCallback(current_page)}}
        >
          <i className='previous'/>
        </button>
      </li> : ""}

      {pages}

      {current_page + 1 < max_pages ? <li className='page-item next'>
        <button
          className='page-link'
          onClick={() => {pageChangeCallback(current_page + 2)}}
        >
          <i className='next'/>
        </button>
      </li> : ""}
    </ul>
  </span>
}

export {Paginator}
