const FRACTION = process.env.REACT_APP_FRACTION as string

export class CardTypeModel {
  id: number = 0;
  title: string = "";
  percentage: number = 0;
}

export class CardModel {
  id: number = 0;
  card_number: string = "";
  card_type: number = 0;
  first_name: string = "";
  last_name: string = "";
  mobile_phone: string = "";
  balance: number = 0;
  issued: Date = new Date();

  constructor(val?: CardModel) {
    if (val) {
      this.id = val.id;
      this.card_number = val.card_number;
      this.card_type = val.card_type;
      this.first_name = val.first_name;
      this.last_name = val.last_name;
      this.mobile_phone = val.mobile_phone;
      this.balance = val.balance;
      this.issued = val.issued;
    }
  }

  public get_balance() {
    return this.balance / +FRACTION;
  }
}

export class CardTransactionModel {
  id: number = 0;
  card_number: string = "";
  amount: number = 0;
  origin: string = "";
  date: Date = new Date();

  constructor(val?: CardTransactionModel) {
    if (val) {
      this.id = val.id;
      this.card_number = val.card_number;
      this.amount = val.amount;
      this.origin = val.origin;
      this.date = val.date;
    }
  }

  public get_amount() {
    return this.amount / +FRACTION;
  }

  public set_amount(balance: number) {
    this.amount = balance * +FRACTION;
  }
}
