import {
  procedureFromInterface,
  ProcedureModel,
  ProcedureInterface, ExtraFieldGroup
} from "../../procedures/_models";
import {PersonModel} from "../../persons/_models";
import {
  bodyAreaFromInterface,
  BodyAreaInterface,
  BodyAreaModel,
  BodyAreasUpdateInterface, BodyAreasUpdateModel
} from "../../bodyAreas/_models";
import {fraction} from "../../generic/helpers/fraction";
import {QueryParamsModel, QueryParamsInterface} from "../../generic/helpers/queryParams";
import moment from "moment";


export interface ServiceParamsInterface extends QueryParamsInterface {
  client_id?: number;
  procedure_id?: number;
  operator_id?: number;
  correction?: boolean;
  created_at?: string;
  modified_at?: number;
}

export class ServiceParams extends QueryParamsModel {
  client_id?: number;
  procedure_id?: number;
  operator_id?: number;
  correction?: boolean;
  created_at: string = new Date().toISOString().split('T')[0];
  modified_at?: number;

  extract(): {} {
    let params = super.extract() as ServiceParamsInterface;
    if (this.client_id) { params["client_id"] = this.client_id; }
    if (this.procedure_id) { params["procedure_id"] = this.procedure_id; }
    if (this.operator_id) { params["operator_id"] = this.procedure_id; }
    if (this.correction) { params["correction"] = this.correction; }
    if (this.created_at) { params["created_at"] = this.created_at; }
    if (this.modified_at) { params["modified_at"] = this.modified_at; }

    return params;
  }
}


export interface ServiceInterface {
  id?: number;
  client: PersonModel;
  procedure: ProcedureInterface;
  body_areas: BodyAreaInterface[];
  session: number;
  operator: PersonModel;
  correction: boolean;
  extra?: ExtraFieldGroup;
  bonus_card?: string;
  pay_cash: number;
  pay_pos: number;
  pay_c2c: number;
  pay_bonus: number;
  bonus_transaction_id: number,
  earned_bonus: number,
  note?: string;
  created_at: Date;
  modified_at?: Date;
}

export interface ServiceCreateInterface {
  id?: number;
  client_id: number;
  procedure_id: number;
  body_areas: number[];
  session: number;
  operator_id: number;
  correction: boolean;
  extra?: ExtraFieldGroup;
  bonus_card?: string;
  pay_cash: number;
  pay_pos: number;
  pay_c2c: number;
  pay_bonus: number;
  note?: string;
  created_at?: string;
}


export interface ServiceUpdateInterface {
  id: number;
  client_id?: number;
  procedure_id?: number;
  body_areas?: BodyAreasUpdateInterface;
  session?: number;
  operator_id?: number;
  correction?: boolean;
  extra?: ExtraFieldGroup;
  bonus_card?: string;
  pay_cash?: number;
  pay_pos?: number;
  pay_c2c?: number;
  pay_bonus?: number;
  note?: string;
  created_at?: string;
}


export class ServiceModel {
  constructor(
    public id?: number,
    public client: PersonModel = new PersonModel(),
    public procedure: ProcedureModel = new ProcedureModel(),
    public bodyAreas: BodyAreaModel[] = [] as BodyAreaModel[],
    public session: number = 0,
    public operator: PersonModel = new PersonModel(),
    public correction: boolean = false,
    public extra?: ExtraFieldGroup,
    public bonusCard?: string,
    private _payCash: number = 0,
    private _payPos: number = 0,
    private _payC2C: number = 0,
    private _payBonus: number = 0,
    public note?: string,
    public createdAt: Date = new Date(),
    public modifiedAt?: Date
  ) {}

  get payCash() {
    return this._payCash / fraction();
  }

  set payCash(a: number) {
    this._payCash = a * fraction();
  }

  get payPos() {
    return this._payPos / fraction();
  }

  set payPos(a: number) {
    this._payPos = a * fraction();
  }

  get payC2C() {
    return this._payC2C / fraction();
  }

  set payC2C(a: number) {
    this._payC2C = a * fraction();
  }

  get payBonus() {
    return this._payBonus / fraction();
  }

  set payBonus(a: number) {
    this._payBonus = a * fraction();
  }

  fromInterface(i: ServiceInterface) {
    let bodyAreas: BodyAreaModel[] = [] as BodyAreaModel[];

    i.body_areas.map(bodyArea => {
      bodyAreas.push(bodyAreaFromInterface(bodyArea))
      return null;
    });

    this.id = i.id;
    this.client = i.client;
    this.procedure = procedureFromInterface(i.procedure);
    this.bodyAreas = bodyAreas;
    this.session = i.session;
    this.operator = i.operator;
    this.correction = i.correction;
    this.extra = i.extra;
    this.bonusCard = i.bonus_card;
    this._payCash = i.pay_cash;
    this._payPos = i.pay_pos;
    this._payC2C = i.pay_c2c;
    this._payBonus = i.pay_bonus
    this.note = i.note;
    this.createdAt = i.created_at;
    this.modifiedAt = i.modified_at;
  }

  asInterface(): ServiceInterface {
    let bodyAreas: BodyAreaInterface[] = [] as BodyAreaInterface[];
    this.bodyAreas.map(bodyArea => {
      bodyAreas.push(bodyArea.asInterface());
      return null;
    });

    let service = {
      "client": this.client,
      "procedure": this.procedure.asUpdateInterface(),
      "body_areas": bodyAreas,
      "session": this.session,
      "operator": this.operator,
      "correction": this.correction,
      "extra": this.extra,
      "pay_cash": this._payCash,
      "pay_pos": this._payPos,
      "pay_c2c": this._payC2C,
      "pay_bonus": this._payBonus,
      "created_at": this.createdAt,
    } as ServiceInterface;

    if (this.id) { service["id"] = this.id }
    if (this.modifiedAt) { service["modified_at"] = this.modifiedAt }
    if (this.bonusCard) { service["bonus_card"] = this.bonusCard }
    if (this.note) { service["note"] = this.note }

    return service
  }

  asCreateInterface(): ServiceCreateInterface {
    let bodyAreas: number[] = [];
    this.bodyAreas.map(bodyArea => {
      if (bodyArea.id) {
        bodyAreas.push(bodyArea.id);
      }
      return null;
    });

    let service = {
      "client_id": this.client.id,
      "procedure_id": this.procedure.id,
      "body_areas": bodyAreas,
      "session": this.session,
      "operator_id": this.operator.id,
      "correction": this.correction,
      "pay_cash": this._payCash,
      "pay_pos": this._payPos,
      "pay_c2c": this._payC2C,
      "pay_bonus": this._payBonus,
    } as ServiceCreateInterface;

    if (this.extra) { service["extra"] = this.extra }
    if (this.bonusCard) { service["bonus_card"] = this.bonusCard }
    if (this.note) { service["note"] = this.note }
    if (this.createdAt) { service["created_at"] = moment(this.createdAt).format("YYYY-MM-DDTHH:mm:ss.sss") }

    return service
  }

  asUpdateInterface(body_areas: BodyAreasUpdateModel): ServiceUpdateInterface {

    let service = {
      "client_id": this.client.id,
      "procedure_id": this.procedure.id,
      "body_areas": body_areas,
      "session": this.session,
      "operator_id": this.operator.id,
      "correction": this.correction,
      "pay_cash": this._payCash,
      "pay_pos": this._payPos,
      "pay_c2c": this._payC2C,
      "pay_bonus": this._payBonus,
    } as ServiceUpdateInterface;

    if (this.id) { service["id"] = this.id }
    if (this.extra) { service["extra"] = this.extra }
    if (this.bonusCard) { service["bonus_card"] = this.bonusCard }
    if (this.note) { service["note"] = this.note }
    if (this.createdAt) { service["created_at"] = moment(this.createdAt).format("YYYY-MM-DDTHH:mm:ss.sss") }

    return service
  }
}

export function serviceModelFromInterface(i: ServiceInterface): ServiceModel {
  let service = new ServiceModel();
  service.fromInterface(i)

  return service;
}
