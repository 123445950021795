/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState, useCallback, useRef, useMemo} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {AddBodyAreaModal} from "../modals/AddBodyAreaModal";
import {bodyAreaFromInterface, BodyAreaInterface, BodyAreaParams} from "../_models";
import {getBodyAreas} from "../_requests";
import {BodyAreaRow} from "../components/BodyAreaRow";
import {Page} from "../../generic/_models";
import {Paginator} from "../../generic/paginator/Paginator";
import {ProcedureInterface, ProcedureQueryModel as ProcedureQueryParamsModel} from "../../procedures/_models";
import {get_procedures} from "../../procedures/_requests";

type Props = {
  className: string
}

const BodyAreasTable: React.FC<Props> = ({className}) => {
  let refreshCount = useRef(0);
  let [bodyAreas, setBodyAreas] = useState<Page<BodyAreaInterface>>(new Page<BodyAreaInterface>());
  let [requestParams, setRequestParams] = useState<BodyAreaParams>(new BodyAreaParams());
  let [procedures, setProcedures] = useState<Page<ProcedureInterface>>(new Page<ProcedureInterface>());
  const procedureRequestParams = useMemo(() => {
    let params = new ProcedureQueryParamsModel();
    params.limit = 100000;
    return params;
  }, []);

  const requestBodyAreas = async (params: BodyAreaParams) => {
    try {
      setBodyAreas(new Page<BodyAreaInterface>());
      const {data} = await getBodyAreas(params.extract());
      setBodyAreas(data);
    } catch (error) {
      console.log(error);
    }
  }

  const requestProcedures = async (params: ProcedureQueryParamsModel) => {
    try {
      setProcedures(new Page<ProcedureInterface>());
      const {data} = await get_procedures(params);
      setProcedures(data);
    } catch (error) {
      console.log(error);
    }
  }

  const refreshTable = useCallback(() => {
    requestBodyAreas(requestParams).then();
  }, [requestParams]);

  const refreshProcedures = useCallback(() => {
    requestProcedures(procedureRequestParams).then()
  }, [procedureRequestParams]);

  useEffect(() => {
    if (refreshCount.current === 0) {
      refreshTable();
      refreshProcedures();
      refreshCount.current++;
    }
  }, [refreshTable, refreshProcedures]);

  const pageChange = (page_num: number) => {
    let new_params = requestParams;
    new_params.page = page_num - 1;
    requestBodyAreas(new_params).then();
    setRequestParams(new_params);
  };

  const handlePageSize = (e: { target: { value: any; }; }) => {
    const page_size = Number(e.target.value);
    let new_params = requestParams;
    new_params.limit = page_size;
    requestBodyAreas(new_params).then();
    setRequestParams(new_params);
  };

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Body Areas</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>The list of body areas that affect pricing</span>
        </h3>
        <div className='card-toolbar'>
          {/* begin::Menu */}
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-bs-toggle='modal'
            data-bs-target='#modal_add_body_area'
          >
            <KTSVG path='/media/icons/duotune/general/gen035.svg' className='svg-icon-2' />
          </button>
          {/* begin::Menu 1 */}
          <AddBodyAreaModal callBack={refreshTable} procedures={procedures} refreshProcedures={refreshProcedures}/>
          {/* end::Menu 1 */}
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-50px rounded-start'>Title</th>
                <th className='min-w-50px'>Price</th>
                <th className='min-w-50px'>Procedure</th>
                <th className='min-w-50px'>Created at</th>
                <th className='min-w-50px'>Modified at</th>
                <th className='min-w-100px text-end rounded-end'></th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
            {bodyAreas.items.map(bodyArea => {
              return <BodyAreaRow
                bodyArea={bodyAreaFromInterface(bodyArea)}
                callback={refreshTable}
                procedures={procedures}
                refreshProcedures={refreshProcedures}
              />
            })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
          <Paginator
            pageChangeCallback={pageChange}
            pageSizeCallback={handlePageSize}
            current_page={bodyAreas.current_page}
            max_pages={bodyAreas.max_pages}
            pageSizes={[10, 20, 50, 100]}
          />
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {BodyAreasTable}
