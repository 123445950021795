import axios from 'axios'
import {Page} from "../../generic/_models";
import {DailySummaryInInterface, DailySummaryOutInterface} from "../_models/dailySummary";

export const RU_DAILY_SUMMARY_URL = `${process.env.REACT_APP_API_PATH}/daily_summary`


export function getDailySummaries(
  params: {} = {},
  id?: number
) {
  let url = id ? `${RU_DAILY_SUMMARY_URL}/${id}/` : RU_DAILY_SUMMARY_URL;
  return axios.get<Page<DailySummaryInInterface>>(`${url}/`, { params: params});
}

export function updateDailySummary(summary: DailySummaryOutInterface) {
  return axios.put<DailySummaryInInterface>(`${RU_DAILY_SUMMARY_URL}/`, summary)
}
