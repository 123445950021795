/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useRef, useState} from 'react'
import {EditText} from "react-edit-text";
import {ExtraField, ExtraFieldGroup} from "../../procedures/_models";


type ExtraFieldProps = {
  field: ExtraField,
  onChangeCallback?: Function,
  onSaveCallback?: Function,
  inline: boolean
}

const ExtraFieldInput: React.FC<ExtraFieldProps> = (
  {
    field,
    onChangeCallback,
    onSaveCallback,
    inline = false
  }) => {
  let [fieldValue, setFieldValue] = useState<string | undefined>(field.value);
  let instantValue = useRef<ExtraField>(field);

  const changeHandler = (e: { target: { value: any; }; }) => {
    setFieldValue(e.target.value);
    instantValue.current["value"] = e.target.value;

    if (!inline && onChangeCallback !== undefined) {
      onChangeCallback(instantValue.current);
    }
  }

  const saveHandler = () => {
    if (onSaveCallback !== undefined) {
      onSaveCallback(instantValue.current);
    }
  }

  if (inline) {
    return <span className="d-flex flex-row">
      <label className="mb-2" htmlFor={field.key}>{field.label}: </label>
      <EditText
        className="ms-3 mb-3"
        type={field.type}
        value={fieldValue}
        placeholder={field.label}
        id={field.key}
        onChange={(e) => changeHandler(e)}
        onSave={() => saveHandler() }
        inputClassName="form-control ms-3 mb-3"
      />
    </span>
  } else {
    return <span>
      <label className="mb-2" htmlFor={field.key}>{field.label}</label>
      <input
        className="form-control mb-3"
        id={field.key}
        type={field.type}
        value={fieldValue}
        placeholder={field.label}
        onChange={(e) => changeHandler(e)}
      />
    </span>
  }
}


type Props = {
  extraFieldGroup: ExtraFieldGroup | undefined,
  returnCallback: Function,
  inline: boolean
}

const ExtraFieldGroupComponent: React.FC<Props> = (
  {
    extraFieldGroup,
    returnCallback,
    inline= false
  }) => {
  let [extraValues, setExtraValues] = useState<ExtraFieldGroup>(() => {
      if (extraFieldGroup) {
        return extraFieldGroup;
      } else {
        let newValues: ExtraFieldGroup = {
          "id": 0,
          "label": "",
          "fields": []
        };

        return newValues;
      }
  });

  console.log("extraFieldGroup: ", extraFieldGroup);
  console.log("extraValues: ", extraValues);

  if (extraFieldGroup) {
    return (
      <div className={`d-flex border-warning border border-dashed ${inline ? "flex-column" : "flex-row mb-3 gap-2"}`}>
        {
          extraFieldGroup.fields.map((field, index) => {
            const changeHandler = (v: ExtraField) => {
              extraFieldGroup.fields[index] = v;
              setExtraValues(extraFieldGroup);
              returnCallback(extraFieldGroup);
            }

            return <ExtraFieldInput field={field} inline={inline} onChangeCallback={changeHandler} onSaveCallback={changeHandler}/>
          })}
      </div>
    )
  } else {
    return <></>
  }
}

export {ExtraFieldGroupComponent, ExtraFieldInput}
