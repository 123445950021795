/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {add_card, get_card_types} from "../_requests";
import {CardModel, CardTypeModel} from "../_models";
import {Page} from "../../generic/_models";

type Props = {
  callBack: Function,
}

const AddCardModal: FC<Props> = ({callBack}) => {
  let [firstName, setFirstName] = useState("");
  let [lastName, setLastName] = useState("");
  let [mobilePhone, setMobilePhone] = useState("");
  let [cardNumber, setCardNumber] = useState("");
  let [selectedCardType, setSelectedCardType] = useState(1);

  async function createHandler(_: any) {
    let card = new CardModel()
    card.first_name = firstName;
    card.last_name = lastName;
    card.mobile_phone = mobilePhone;
    card.card_number = cardNumber;
    card.card_type = selectedCardType;

    const resp = await add_card(card);
    await callBack();

    if (resp.status !== 202) {
      //TODO: Display message
    }
  }

  // Card types
  let [cardTypes, setCardTypes] = useState<Page<CardTypeModel>>(new Page<CardTypeModel>());

  useEffect(() => {
    const requestApps = async () => {
      try {
        const {data} = await get_card_types();
        setCardTypes(data);
      } catch (error) {
        console.log(error);
      }
    }
    requestApps().then();
  }, []);

  const handleChangeString = (e: { target: { value: any; }; }, setFn: (arg0: any) => void) => {
    setFn(e.target.value);
  };

  const handleChangeNumber = (e: { target: { value: any; }; }, setFn: (arg0: any) => void) => {
    setFn(Number(e.target.value));
  };

  return (
    <div className='modal fade' id='modal_add_card' aria-hidden='true'>
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
          </div>

          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>Add New Card</h1>
            </div>

            <input
              type="text"
              className="form-control mb-5"
              placeholder="Card Number"
              aria-label="Card Number"
              aria-describedby="basic-addon1"
              onChange={(e) => handleChangeString(e, setCardNumber)}
            />
            <div className='mb-10'>
              <label className='form-label fw-bold'>Type:</label>

              <div>
                <select
                  className='form-select form-select-solid'
                  data-kt-select2='true'
                  data-placeholder='Select option'
                  data-allow-clear='true'
                  defaultValue={'1'}
                  onChange={(e) => handleChangeNumber(e, setSelectedCardType)}
                >
                  {cardTypes.items.map((cardType) => {
                    return <option value={cardType.id}>{cardType.title} {cardType.percentage}%</option>
                  })}
                </select>
              </div>
            </div>
            <input
              type="text"
              className="form-control mb-5"
              placeholder="First Name"
              aria-label="First Name"
              aria-describedby="basic-addon1"
              onChange={(e) => handleChangeString(e, setFirstName)}
            />
            <input
              type="text"
              className="form-control mb-5"
              placeholder="Last Name"
              aria-label="Last Name"
              aria-describedby="basic-addon1"
              onChange={(e) => handleChangeString(e, setLastName)}
            />
            <input
              type="text"
              className="form-control mb-5"
              placeholder="Mobile Phone"
              aria-label="Mobile Phone"
              aria-describedby="basic-addon1"
              onChange={(e) => handleChangeString(e, setMobilePhone)}
            />
            <div
              className='btn btn-light-success fw-bolder w-100 mb-8'
              data-bs-dismiss='modal'
              onClick={createHandler}
            >Add</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {AddCardModal}
