/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useCallback, useEffect, useRef, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {AddWithdrawModal} from "../modals/AddWithdrawModal";
import {withdrawFromInterface, WithdrawInterface, QueryParamsModel} from "../_models";
import {getWithdraws} from "../_requests";
import {WithdrawRow} from "../components/WithdrawRow";
import {Page} from "../../generic/_models";
import {Paginator} from "../../generic/paginator/Paginator";

type Props = {
  className: string
}

const WithdrawsTable: React.FC<Props> = ({className}) => {
  let refreshCount = useRef(0);
  let [withdraws, setWithdraws] = useState<Page<WithdrawInterface>>(new Page<WithdrawInterface>());
  let [requestParams, setRequestParams] = useState<QueryParamsModel>(new QueryParamsModel());

  const requestWithdraws = async (params: QueryParamsModel) => {
    try {
      setWithdraws(new Page<WithdrawInterface>())
      const {data} = await getWithdraws(params);
      setWithdraws(data);
    } catch (error) {
      console.log(error);
    }
  }

  const refreshTable = useCallback(() => {
    requestWithdraws(requestParams).then();
  }, [requestParams]);

  useEffect(() => {
    if (refreshCount.current === 0) {
      refreshTable();
    }
  }, [refreshTable]);

  const pageChange = (page_num: number) => {
    let new_params = requestParams;
    new_params.page = page_num - 1;
    requestWithdraws(new_params).then();
    setRequestParams(new_params);
  };

  const handlePageSize = (e: { target: { value: any; }; }) => {
    const page_size = Number(e.target.value);
    let new_params = requestParams;
    new_params.limit = page_size;
    requestWithdraws(new_params).then();
    setRequestParams(new_params);
  };

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>withdraws</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>The list of daily withdraws</span>
        </h3>
        <div className='card-toolbar'>
          {/* begin::Menu */}
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-bs-toggle='modal'
            data-bs-target='#modal_new_withdraw'
          >
            <KTSVG path='/media/icons/duotune/general/gen035.svg' className='svg-icon-2' />
          </button>
          {/* begin::Menu 1 */}
          <AddWithdrawModal callBack={refreshTable}/>
          {/* end::Menu 1 */}
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-50px rounded-start'>Description</th>
                <th className='min-w-50px'>Amount</th>
                <th className='min-w-50px'>Created at</th>
                <th className='min-w-50px'>Modified at</th>
                <th className='min-w-100px text-end rounded-end'></th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
            {withdraws.items.map(withdraw => {
              return <WithdrawRow withdraw={withdrawFromInterface(withdraw)} callback={refreshTable}/>
            })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
          <Paginator
            pageChangeCallback={pageChange}
            pageSizeCallback={handlePageSize}
            current_page={withdraws.current_page}
            max_pages={withdraws.max_pages}
            pageSizes={[10, 20, 50, 100]}
          />
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {WithdrawsTable}
