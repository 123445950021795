/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useCallback, useEffect, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {AddProcedureModal} from "../modals/AddProcedureModal";
import {
  ExtraFieldGroup,
  procedureFromInterface,
  ProcedureQueryModel,
  ProcedureInterface
} from "../_models";
import {get_procedures, getExtraParameterTypes} from "../_requests";
import {ProcedureRow} from "../components/ProcedureRow";
import {Page} from "../../generic/_models";
import {Paginator} from "../../generic/paginator/Paginator";

type Props = {
  className: string
}

const ProcedureTable: React.FC<Props> = ({className}) => {
  let [procedures, setProcedures] = useState<Page<ProcedureInterface>>(new Page<ProcedureInterface>());
  let [requestParams, setRequestParams] = useState<ProcedureQueryModel>(new ProcedureQueryModel());
  let [extraParameters, setExtraParameters] = useState<ExtraFieldGroup[]>([])

  const requestExtraParameters = async () => {
    try {
      setExtraParameters([]);
      let {data} = await getExtraParameterTypes();

      if (data) {
        setExtraParameters(data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const requestProcedures = async (params: ProcedureQueryModel) => {
    try {
      setProcedures(new Page<ProcedureInterface>());
      const {data} = await get_procedures(params.extract());
      setProcedures(data);
    } catch (error) {
      console.log(error);
    }
  }

  const refreshTable = useCallback(() => {
    requestProcedures(requestParams).then();
    requestExtraParameters().then();
  }, [requestParams]);

  useEffect(() => {
    refreshTable();
  }, [refreshTable]);

  const pageChange = (page_num: number) => {
    let new_params = requestParams;
    new_params.page = page_num - 1;
    requestProcedures(new_params).then();
    setRequestParams(new_params);
  };

  const handlePageSize = (e: { target: { value: any; }; }) => {
    const page_size = Number(e.target.value);
    let new_params = requestParams;
    new_params.limit = page_size;
    requestProcedures(new_params).then();
    requestExtraParameters().then();
    setRequestParams(new_params);
  };

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Procedures</span>
        </h3>
        <div className='card-toolbar'>
          {/* begin::Menu */}
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-bs-toggle='modal'
            data-bs-target='#modal_add_procedure'
          >
            <KTSVG path='/media/icons/duotune/general/gen035.svg' className='svg-icon-2' />
          </button>
          {/* begin::Menu 1 */}
          <AddProcedureModal
            refreshProcedures={refreshTable}
            extraParameters={extraParameters}
            refreshExtraParameters={requestExtraParameters}
          />
          {/* end::Menu 1 */}
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-50px rounded-start'>Title</th>
                <th className='min-w-100px'>Extra Fields</th>
                <th className='min-w-100px text-end rounded-end'></th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
            {procedures.items.map(procedure => {
              return <ProcedureRow
                procedure={procedureFromInterface(procedure)}
                callback={refreshTable}
                extraParameters={extraParameters}
                refreshExtraParameters={requestExtraParameters}
              />
            })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
          <Paginator
            pageChangeCallback={pageChange}
            pageSizeCallback={handlePageSize}
            current_page={procedures.current_page}
            max_pages={procedures.max_pages}
            pageSizes={[10, 20, 50, 100]}
          />
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {ProcedureTable}
