import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {BodyAreasTable} from "../../modules/bodyAreas/tables/BodyAreasTable";

const BodyAreasPage = () => {
  return <>
    {/* begin::Row */}
    <div className='row g-5 g-xl-8'>
      <BodyAreasTable className="mb-5 mb-xl-8"/>
    </div>
    {/* end::Row */}
  </>;
}

const BodyAreasWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'GENERAL.BODY_AREAS'})}</PageTitle>
      <BodyAreasPage />
    </>
  )
}

export {BodyAreasWrapper}
