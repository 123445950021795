/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {ServiceModel, ServiceUpdateInterface} from "../_models/service";
import {delete_service, update_service} from "../_requests/service";
import {EditText} from "react-edit-text";
import {ExtraField, ExtraFieldGroup} from "../../procedures/_models";
import {ExtraFieldGroupComponent} from "./ExtraParameters";
import {handleChangeBoolean, handleChangeNumber, handleChangeString} from "../../generic/helpers/changeHandlers";

type Props = {
  service: ServiceModel,
  refreshServices: Function,
  selectCallBack: Function
}

const ServiceRow: React.FC<Props> = ({service, refreshServices, selectCallBack}) => {
  let fraction = process.env.REACT_APP_FRACTION ? +process.env.REACT_APP_FRACTION : 100;
  // let [extraValues, setExtraValues] = useState<ExtraFieldGroup[]>(() => {
  //   if (service.extra) {
  //     return service.extra;
  //   } else {
  //     return [] as ExtraFieldGroup[];
  //   }
  // });
  let [payCash, setPayCash] = useState(service.payCash);
  let [payPos, setPayPos] = useState(service.payPos);
  let [payC2C, setPayC2C] = useState(service.payC2C);
  let [payBonus, setPayBonus] = useState(service.payBonus);
  let [session, setSession] = useState(service.session);
  let [correction, setCorrection] = useState(service.correction);
  let [note, setNote] = useState(service.note);
  let [bonusCard, setBonusCard] = useState(() => {
    if (service.bonusCard) {
      return service.bonusCard;
    } else {
      return ""
    }
  });

  // const handleExtraValues = (e: ExtraValue) => {
  //   setExtraValues(e);
  //   updateHandler().then();
  // }

  async function updateHandler(updated_fields: {}) {
    let payload = {
      "id": service.id,
      ...updated_fields
    } as ServiceUpdateInterface;

    const resp = await update_service(payload);
    await refreshServices();

    if (resp.status !== 202) {
      console.log(resp.statusText);
    }
  }

  const handleDelete = async () => {
    if (service.id) {
      const resp = await delete_service(service.id);
      await refreshServices();

      if (resp.status !== 202) {
        console.log(resp.statusText);
      }
    }
  };

  // const editButtonHandler = () => {
  //   selectCallBack(service);
  // }

  return (
    <tr>
      <td>
        <div className='d-flex align-items-center'>
          <div className='symbol symbol-50px me-5'>
            <span className='symbol-label bg-light'>
              <img
                src={toAbsoluteUrl('/media/svg/avatars/014-girl-7.svg')}
                className='h-75 align-self-end'
                alt=''
              />
            </span>
          </div>
          <div className='d-flex justify-content-start flex-column'>
            <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
              {service.client.first_name} {service.client.last_name}
            </div>
            <span className='text-muted fw-semibold text-muted d-block fs-7'>
              Phone: {service.client.phone}
            </span>
            <span className='text-muted fw-semibold text-muted d-block fs-7'>
              Operator: {service.operator.first_name} {service.operator.last_name}
            </span>
            <span className='text-muted fw-semibold text-muted d-block fs-7'>
              <span className="d-flex flex-row align-items-center">
                <span className="me-2">Note:</span>
                <EditText
                  inline={true}
                  type='text'
                  className='mb-1 fs-6 ms-3'
                  inputClassName="form-control"
                  value={note}
                  showEditButton={true}
                  editButtonContent={<i className="bi bi-pen-fill"/>}
                  editButtonProps={{className: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm"}}
                  onChange={(e) => handleChangeString(e, setNote)}
                  onSave={({ name, value, previousValue }) => updateHandler({"note": note}).then()}
                />
              </span>
            </span>
          </div>
        </div>
      </td>
      <td>
        <div className='mb-1 fs-6'>
          {service.procedure.title}
        </div>
      </td>
      <td>
        <div className='mb-1 fs-6'>
          {service.bodyAreas.map(area => {
            return <div>{area.title}: {area.price}</div>
          })}
        </div>
      </td>
      <td>
        <div className='d-block mb-1 fs-6'>
          <EditText
            type='number'
            className='mb-1 fs-6 ms-3'
            value={session.toString()}
            onSave={({ name, value, previousValue }) => updateHandler({"session": value}).then()}
          />
        </div>
      </td>
      <td>
        <div className='d-block mb-1 fs-6'>
          <div className="form-check mb-5">
            <input
              className="form-check-input mb-5"
              type="checkbox"
              id="flexCheckDefault"
              onChange={(e) => {
                handleChangeBoolean(e, setCorrection).then();
                updateHandler({"correction": correction}).then();
              }}
            />
          </div>
        </div>
      </td>
      <td>
        <div className='d-block mb-1 fs-6'>
          <EditText
            type='text'
            className='mb-1 fs-6 ms-3'
            inputClassName="form-control"
            value={bonusCard}
            showEditButton={true}
            editButtonContent={<i className="bi bi-pen-fill"/>}
            editButtonProps={{className: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm"}}
            onChange={(e) => handleChangeString(e, setBonusCard)}
            onSave={({ name, value, previousValue }) => updateHandler({"bonus_card": bonusCard}).then()}
          />
        </div>
      </td>
      <td>
        <div className='d-block mb-1 fs-6'>
          <ExtraFieldGroupComponent
            extraFieldGroup={service.extra}
            returnCallback={(value: any) => {updateHandler({"extra": value}).then()}}
            inline={true}
          />
        </div>
      </td>
      <td>
        <div className='fs-6 d-flex flex-row'>
          <span>Cash:</span>
          <EditText
            type='number'
            className='mb-1 fs-6 ms-3'
            inputClassName="form-control mw-100px"
            value={payCash.toString()}
            onChange={(e) => handleChangeNumber(e, setPayCash)}
            onSave={({ name, value, previousValue }) => updateHandler({"pay_cash": payCash * fraction}).then()}
          />
        </div>
        <div className='fs-6 d-flex flex-row'>
          <span>Pos:</span>
          <EditText
            type='number'
            className='mb-1 fs-6 ms-3'
            inputClassName="form-control mw-100px"
            value={payPos.toString()}
            onChange={(e) => handleChangeNumber(e, setPayPos)}
            onSave={({ name, value, previousValue }) => updateHandler({"pay_pos": payPos * fraction}).then()}
          />
        </div>
        <div className='fs-6 d-flex flex-row'>
          <span>C2C:</span>
          <EditText
            type='number'
            className='mb-1 fs-6 ms-3'
            inputClassName="form-control mw-100px"
            value={payC2C.toString()}
            onChange={(e) => handleChangeNumber(e, setPayC2C)}
            onSave={({ name, value, previousValue }) => updateHandler({"pay_c2c": payC2C * fraction}).then()}
          />
        </div>
        <div className='fs-6 d-flex flex-row'>
          <span>Bonus:</span>
          <EditText
            type='number'
            className='mb-1 fs-6 ms-3'
            inputClassName="form-control mw-100px"
            value={payBonus.toString()}
            onChange={(e) => handleChangeNumber(e, setPayBonus)}
            onSave={({ name, value, previousValue }) => updateHandler({"pay_bonus": payBonus * fraction}).then()}
          />
        </div>
      </td>
      <td className='text-end'>
        {/*<button*/}
        {/*  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'*/}
        {/*  onClick={editButtonHandler}*/}
        {/*  data-bs-toggle='modal'*/}
        {/*  data-bs-target='#modal_edit_service'*/}
        {/*>*/}
        {/*  <i className="bi bi-pen-fill"/>*/}
        {/*</button>*/}

        <button
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
          onClick={handleDelete}
        >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </button>
      </td>
    </tr>
  )
}

export {ServiceRow}
