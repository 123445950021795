import {isEmptyString} from "../generic/helpers/strings";
import moment from "moment/moment";

export interface QueryParamsInterface {
  description?: string;
  amount?: number;
  created_at?: number;
  limit: number;
  offset: number;
  page: number;
}

export class QueryParamsModel {
  description: string | undefined = undefined;
  amount: number | undefined = undefined;
  created_at: number | undefined = undefined;
  limit: number = 10;
  offset: number = 0;
  page: number = 0;

  isOk() : boolean {
    return (!isEmptyString(this.description))
      || (this.amount !== undefined)
      || (this.created_at !== undefined);
  }

  extract() : any {
    let params: QueryParamsInterface = {limit: this.limit, offset: this.offset, page: this.page};
    if (!isEmptyString(this.description)) { params["description"] = this.description; }
    if (this.amount !== undefined) { params["amount"] = this.amount; }
    if (this.created_at !== undefined) { params["created_at"] = this.created_at; }

    return params;
  }
}

export interface ExpenseInterface {
  id?: number;
  description: string;
  amount: number;
  created_at?: string;
  modified_at?: string;
}

export interface ExpenseCreateInterface {
  description: string;
  amount: number;
  created_at?: string;
}

export interface ExpenseUpdateInterface {
  id: number;
  description?: string;
  amount?: number;
  created_at?: string;
  modified_at?: string;
}

export class ExpenseModel {
  constructor(
    public id: number = 0,
    public description: string = "",
    private _amount: number = 0,
    public createdAt: string = moment(new Date()).format("YYYY-MM-DD"),
    public modifiedAt: Date = new Date()
  ) {}

  get amount() {
    return this._amount / 100;
  }

  set amount(p: number) {
    this._amount = p * 100;
  }

  fromInterface(external: ExpenseInterface) {
    this.id = external.id ? external.id : 0;
    this.description = external.description;
    this._amount = external.amount;
    this.createdAt = external.created_at ? external.created_at : moment(new Date()).format("YYYY-MM-DD");
    this.modifiedAt = external.modified_at ? new Date(external.modified_at) : new Date();
  }

  asCreateInterface(): ExpenseCreateInterface {
    return {
      "description": this.description,
      "amount": this._amount,
      "created_at": this.createdAt
    } as ExpenseCreateInterface;
  }

  asUpdateInterface(): ExpenseUpdateInterface {
    return {
      "id": this.id,
      "description": this.description,
      "amount": this._amount,
      "created_at": this.createdAt
    } as ExpenseUpdateInterface;
  }
}

export function expenseFromInterface(b: ExpenseInterface): ExpenseModel {
  let expense = new ExpenseModel();
  expense.fromInterface(b);

  return expense;
}
