/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {add_card_transaction} from "../_requests";
import {CardTransactionModel} from "../_models";

type Props = {
  callBack: Function,
}

const AddCardTransactionModal: FC<Props> = ({callBack})  => {
  let [cardNumber, setCardNumber] = useState("");
  let [amount, setAmount] = useState(0);
  let [origin, setOrigin] = useState("");

  async function createHandler(_: any) {
    let transaction = new CardTransactionModel()
    transaction.card_number = cardNumber;
    transaction.set_amount(amount);
    transaction.origin = origin;

    const resp = await add_card_transaction(transaction);
    await callBack();

    if (resp.status !== 202) {
      //TODO: Display message
    }
  }

  const handleChangeString = (e: { target: { value: any; }; }, setFn: (arg0: any) => void) => {
    setFn(e.target.value);
  };

  const handleChangeNumber = (e: { target: { value: any; }; }, setFn: (arg0: any) => void) => {
    setFn(Number(e.target.value));
  };

  return (
    <div className='modal fade' id='modal_add_card_transaction' aria-hidden='true'>
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
          </div>

          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>Add New Transaction</h1>
            </div>

            <input
              type="number"
              className="form-control mb-5"
              placeholder="Card Number"
              aria-label="Card Number"
              aria-describedby="basic-addon1"
              onChange={(e) => handleChangeString(e, setCardNumber)}
            />
            <input
              type="text"
              className="form-control mb-5"
              placeholder="Amount"
              aria-label="Amount"
              aria-describedby="basic-addon1"
              onChange={(e) => handleChangeNumber(e, setAmount)}
            />
            <input
              type="text"
              className="form-control mb-5"
              placeholder="Origin"
              aria-label="Origin"
              aria-describedby="basic-addon1"
              onChange={(e) => handleChangeString(e, setOrigin)}
            />
            <div
              className='btn btn-light-success fw-bolder w-100 mb-8'
              data-bs-dismiss='modal'
              onClick={createHandler}
            >Add</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {AddCardTransactionModal}
