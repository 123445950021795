import axios from 'axios'
import {PersonModel} from './_models'
import {Page} from "../generic/_models";

export const CRUD_PERSON_URL = `${process.env.REACT_APP_API_PATH}/persons`


export function getPersons(
  params: {} = {},
  id?: number
) {
  let url = id ? `${CRUD_PERSON_URL}/${id}/` : CRUD_PERSON_URL;
  return axios.get<Page<PersonModel>>(`${url}/`, { params: params});
}

export function addPerson(person: PersonModel) {
  return axios.post<PersonModel>(`${CRUD_PERSON_URL}/`, {
    "first_name": person.first_name,
    "last_name": person.last_name,
    "phone": person.phone,
    "staff": person.staff,
    "birthdate": person.birthdate
  })
}

export function updatePerson(person: PersonModel) {
  return axios.put<PersonModel>(`${CRUD_PERSON_URL}/`, {
    "id": person.id,
    "first_name": person.first_name,
    "last_name": person.last_name,
    "phone": person.phone,
    "staff": person.staff,
    "birthdate": person.birthdate
  })
}

export function deletePerson(id: number) {
  return axios.delete<PersonModel>(`${CRUD_PERSON_URL}/${id}/`)
}
