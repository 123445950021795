import axios from 'axios'
import {WithdrawInterface} from './_models'
import {Page} from "../generic/_models";

const CRUD_WITHDRAWS_URL = `${process.env.REACT_APP_API_PATH}/withdraws`


export function getWithdraws(
  params: {} = {},
  id?: number
) {
  let url = id ? `${CRUD_WITHDRAWS_URL}/${id}/` : CRUD_WITHDRAWS_URL;
  return axios.get<Page<WithdrawInterface>>(`${url}/`, { params: params});
}

export function addWithdraw(withdraw: WithdrawInterface) {
  return axios.post<WithdrawInterface>(`${CRUD_WITHDRAWS_URL}/`, withdraw)
}

export function updateWithdraw(withdraw: WithdrawInterface) {
  return axios.put<WithdrawInterface>(`${CRUD_WITHDRAWS_URL}/`, withdraw)
}

export function deleteWithdraw(id: number) {
  return axios.delete<WithdrawInterface>(`${CRUD_WITHDRAWS_URL}/${id}/`)
}
