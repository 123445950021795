import axios from 'axios'
import {CardTypeModel, CardModel, CardTransactionModel} from './_models'
import {Page} from "../generic/_models";

export const CRUD_CARD_TYPEs_URL = `${process.env.REACT_APP_API_PATH}/bonus-card/type/`
export const A_CARD_TYPE_URL = `${process.env.REACT_APP_API_PATH}/bonus-card/type/id/`

export const CRUD_CARDS_URL = `${process.env.REACT_APP_API_PATH}/bonus-card/card/`
export const A_CARD_URL = `${process.env.REACT_APP_API_PATH}/bonus-card/card/id/`

export const CRUD_CARD_TRANSACTION_URL = `${process.env.REACT_APP_API_PATH}/bonus-card/transaction/`
export const A_CARD_TRANSACTION_URL = `${process.env.REACT_APP_API_PATH}/bonus-card/transaction/id/`

// ------------------------ BEGIN Card Type ---------------------------
export function get_card_types() {
  return axios.get<Page<CardTypeModel>>(CRUD_CARD_TYPEs_URL);
}

export function get_a_card_type(id: number) {
  return axios.get<CardTypeModel>(`${A_CARD_TYPE_URL}${id}`);
}

export function add_card_type(card: CardTypeModel) {
  return axios.post<CardTypeModel>(CRUD_CARD_TYPEs_URL, {
    "title": card.title,
    "percentage": card.percentage,
  })
}

export function update_card_type(card: CardTypeModel) {
  return axios.put<CardTypeModel>(CRUD_CARD_TYPEs_URL, card)
}

export function delete_card_type(id: number) {
  return axios.delete<CardTypeModel>(`${A_CARD_TYPE_URL}${id}`)
}

// ------------------------- END Card Type ----------------------------

// -------------------------  BEGIN Card  -----------------------------
export function get_cards() {
  return axios.get<Page<CardModel>>(CRUD_CARDS_URL);
}

export function get_a_card(id: number) {
  return axios.get<CardModel>(`${A_CARD_URL}${id}`);
}

export function add_card(card: CardModel) {
  return axios.post<CardModel>(CRUD_CARDS_URL, {
    "card_number": card.card_number,
    "card_type_id": card.card_type,
    "first_name": card.first_name,
    "last_name": card.last_name,
    "mobile_phone": card.mobile_phone,
  })
}

export function update_card(card: CardModel) {
  return axios.put<CardModel>(CRUD_CARDS_URL, {
    "id": card.id,
    "card_number": card.card_number,
    "card_type_id": card.card_type,
    "first_name": card.first_name,
    "last_name": card.last_name,
    "mobile_phone": card.mobile_phone,
  })
}

export function delete_card(id: number) {
  return axios.delete<CardModel>(`${A_CARD_URL}${id}`)
}
// -------------------------   END Card  ------------------------------

// ---------------------- BEGIN Transaction  --------------------------
export function get_card_transactions() {
  return axios.get<Page<CardTransactionModel>>(CRUD_CARD_TRANSACTION_URL);
}

export function get_a_card_transactions(id: number) {
  return axios.get<CardTransactionModel>(`${A_CARD_TRANSACTION_URL}${id}`);
}

export function add_card_transaction(transaction: CardTransactionModel) {
  return axios.post<CardTransactionModel>(CRUD_CARD_TRANSACTION_URL, {
    "card_number": transaction.card_number,
    "amount": transaction.amount,
    "origin": transaction.origin,
  })
}

export function update_card_transaction(transaction: CardTransactionModel) {
  return axios.put<CardTransactionModel>(CRUD_CARD_TRANSACTION_URL, {
    "id": transaction.id,
    "card_number": transaction.card_number,
    "amount": transaction.amount,
    "origin": transaction.origin,
  })
}
// ----------------------  END Transaction  ---------------------------
