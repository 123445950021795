/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {ExpenseModel} from "../_models";
import {EditText} from "react-edit-text";
import {handleChangeString} from "../../generic/helpers/changeHandlers";
import {deleteExpense, updateExpense} from "../_requests";
import moment from "moment";

type Props = {
  expense: ExpenseModel,
  callback: Function
}

const ExpenseRow: React.FC<Props> = ({expense, callback}) => {
  let [description, setDescription] = useState(expense.description);
  let [amount, setAmount] = useState(expense.amount.toString());
  let [createdAt, setCreatedAt] = useState(moment(expense.createdAt).format("YYYY-MM-DD"));

  const handleSave = async () => {
    let ex = new ExpenseModel();
    ex.id = expense.id;
    ex.description = description;
    ex.createdAt = createdAt;

    if (!amount || amount.match(/^\d{1,}(\.\d{0,4})?$/)) {
      ex.amount = Number(amount);
    }

    const resp = await updateExpense(ex.asUpdateInterface());
    await callback();

    if (resp.status !== 202) {
      console.log(resp.statusText);
    }
  };

  const handleDelete = async () => {
    const resp = await deleteExpense(expense.id);
    await callback();

    if (resp.status !== 202) {
      console.log(resp.statusText);
    }
  };

  const handleCreatedAt = (e: { target: { value: string | number | Date; }; }) => {
    const newDate = moment(new Date(e.target.value)).format('YYYY-MM-DD');
    setCreatedAt(newDate);
  };

  return (
    <tr>
      <td>
        <div className='d-flex align-items-center'>
          <div className='d-flex justify-content-start flex-column'>
            <EditText
              className='text-dark fw-bold text-hover-primary mb-1 fs-6'
              value={description}
              onChange={(e) => handleChangeString(e, setDescription)}
              onSave={handleSave}
            />
          </div>
        </div>
      </td>
      <td>
        <EditText
          type="text"
          className='text-dark fw-bold text-hover-primary mb-1 fs-6'
          value={amount}
          onChange={(e) => handleChangeString(e, setAmount)}
          onSave={handleSave}
        />
      </td>
      <td>
        <EditText
          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
          type="date"
          value={createdAt}
          onChange={handleCreatedAt}
          onSave={handleSave}
        />
      </td>
      <td>
        <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
          {expense.modifiedAt.toString()}
        </div>
      </td>
      <td className='text-end'>
        <button
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
          onClick={handleDelete}
        >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </button>
      </td>
    </tr>
  )
}

export {ExpenseRow}
