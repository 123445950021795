/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {CardTypeModel} from "../_models";
import {update_card_type, delete_card_type} from "../_requests";
import {EditText} from "react-edit-text";

type Props = {
  cardType: CardTypeModel,
}

const CardTypeRow: React.FC<Props> = ({cardType}) => {
  let [title, setTitle] = useState(cardType.title);
  let [percentage, setPercentage] = useState(cardType.percentage);

  async function deleteHandler(_: any) {
    const resp = await delete_card_type(cardType.id);
    if (resp.status === 202) {
      //Todo: refresh table or remove this item from list
    } else {
      //TODO: Display message
    }
  }

  const handleTitleChange = (e: { target: { value: any; }; }, setFn: (arg0: any) => void) => {
    setFn(e.target.value);
  };

  const handlePercentageChange = (e: { target: { value: any; }; }, setFn: (arg0: any) => void) => {
    setFn(Number(e.target.value));
  };

  const handleSave = async () => {
    cardType.title = title;
    cardType.percentage = percentage;

    const resp = await update_card_type(cardType);

    if (resp.status !== 202) {
      console.log(resp.statusText);
    }
  };

  return (
    <tr>
      <th>
        <div className='symbol symbol-50px me-2'>
          <span className='symbol-label'>
            <img
              src={toAbsoluteUrl('/media/svg/card-logos/bitcoin 1.svg')}
              className='h-50 align-self-center'
              alt=''
            />
          </span>
        </div>
      </th>
      <td>
        <EditText
          className='text-dark fw-bold text-hover-primary mb-1 fs-6'
          value={title}
          onChange={(e) => handleTitleChange(e, setTitle)}
          onSave={handleSave}
        />
      </td>
      <td>
        <div className='d-flex flex-column w-100 me-2'>
          <div className='d-flex flex-stack mb-2'>
            <span className='text-muted me-2 fs-7 fw-semibold'>
              <EditText
                className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                value={percentage.toString()}
                onChange={(e) => handlePercentageChange(e, setPercentage)}
                onSave={handleSave}
              />%
            </span>
          </div>
          <div className='progress h-6px w-100'>
            <div
              className='progress-bar bg-primary'
              role='progressbar'
              style={{width: percentage.toString() + '%'}}
            />
          </div>
        </div>
      </td>
      <td className='text-end'>
        <div
          className='btn btn-sm btn-icon btn-bg-light btn-active-color-danger'
          onClick={deleteHandler}
        >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-2' />
        </div>
      </td>
    </tr>
  )
}

export {CardTypeRow}
