/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {CardTransactionModel} from "../_models";

type Props = {
  transaction: CardTransactionModel,
}

const CardTransactionRow: React.FC<Props> = ({transaction}) => {
  const re_transaction = new CardTransactionModel(transaction);
  return (
    <tr>
      <td>
        <div className='d-flex align-items-center'>
          <div className='symbol symbol-50px me-5'>
            <span className='symbol-label bg-light'>
              <img
                src={toAbsoluteUrl('/media/svg/avatars/014-girl-7.svg')}
                className='h-75 align-self-end'
                alt=''
              />
            </span>
          </div>
          <div className='d-flex justify-content-start flex-column'>
            <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
              {re_transaction.id}
            </div>
          </div>
        </div>
      </td>
      <td>
        <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
          {re_transaction.card_number}
        </div>
      </td>
      <td>
        <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
          {re_transaction.get_amount()}₼
        </div>
      </td>
      <td>
        <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
          {re_transaction.origin}
        </div>
      </td>
      <td>
        <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
          {transaction.date.toString()}
        </div>
      </td>
    </tr>
  )
}

export {CardTransactionRow}
