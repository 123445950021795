/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {add_procedure} from "../_requests";
import {ExtraFieldGroup, ProcedureModel} from "../_models";
import {handleChangeString} from "../../generic/helpers/changeHandlers";
import Select from "react-select";
import {buildLabelForExtraParameter} from "../../services/extraParameter";

type Option = {
  "label": string,
  "value": string
};


type Props = {
  extraParameters: ExtraFieldGroup[],
  refreshExtraParameters: Function,
  refreshProcedures: Function,
}

const AddProcedureModal: FC<Props> = ({
    extraParameters,
    refreshProcedures,
    refreshExtraParameters
  })  => {
  let [title, setTitle] = useState("");
  let [extraParametersAsOptions, setExtraParametersAsOptions] = useState<Option[]>([]);
  let [selectedExtraParameters, setSelectedExtraParameters] = useState<ExtraFieldGroup | undefined>();

  async function createHandler(_: any) {
    let procedure = new ProcedureModel()
    procedure.title = title;

    if (selectedExtraParameters) {
      procedure.fields = selectedExtraParameters;
    }

    const resp = await add_procedure(procedure.asCreateInterface());
    await refreshProcedures();

    if (resp.status !== 202) {
      //TODO: Display message
    }

    await clearFields();
  }

  async function clearFields() {
    await setTitle("");
  }

  useEffect(() => {
    const transformExtraParametersAsOptions = (extraParameters: ExtraFieldGroup[]) => {
      let newOptions: any = [];

      extraParameters.map((parameter, index) => {
        let newOption: any = {
          "label": `${buildLabelForExtraParameter(parameter.fields)}`,
          "value": index.toString()
        }

        newOptions.push(newOption);
        return true;
      });

      setExtraParametersAsOptions(newOptions);
    };

    transformExtraParametersAsOptions(extraParameters);
  }, [extraParameters]);

  return (
    <div className='modal fade' id='modal_add_procedure' aria-hidden='true'>
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
          </div>

          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>Add New Procedure</h1>
            </div>

            <input
              type="text"
              className="form-control mb-5"
              placeholder="Title"
              aria-label="Title"
              aria-describedby="basic-addon1"
              onChange={(e) => handleChangeString(e, setTitle)}
            />

            <div className="d-flex flex-row">
              <Select
                className='mb-3'
                data-kt-select2='true'
                data-placeholder='Select body areas'
                data-allow-clear='true'
                isMulti={false}
                isSearchable={false}
                options={extraParametersAsOptions}
                // @ts-ignore
                onChange={(newValues: Option[]) => {
                  if (newValues.length > 0 && extraParameters.length > 0) {
                    newValues.map((value) => {
                      setSelectedExtraParameters(extraParameters[+value.value]);
                      return null;
                    });
                  }
                }}
              />

              <button
                className='btn btn-icon btn-bg-light btn-active-color-primary ms-3'
                onClick={() => {refreshExtraParameters()}}
              >
                <KTSVG path='/media/icons/update.svg' className='svg-icon-3' />
              </button>
            </div>

            <div
              className='btn btn-light-success fw-bolder w-100 mb-8'
              data-bs-dismiss='modal'
              onClick={createHandler}
            >Add</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {AddProcedureModal}
