import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {CardsTable} from "../../modules/cards/tables/CardsTable";

const CardsPage = () => {
  return <>
    {/* begin::Row */}
    <div className='row g-5 g-xl-8'>
      <CardsTable className="mb-5 mb-xl-8"/>
    </div>
    {/* end::Row */}
  </>;
}

const CardsWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.CARDS'})}</PageTitle>
      <CardsPage />
    </>
  )
}

export {CardsWrapper}
