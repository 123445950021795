import {
  ProcedureModel,
  procedureFromInterface,
  ProcedureInterface
} from "../procedures/_models";
import {isEmptyString} from "../generic/helpers/strings";
const FRACTION = process.env.REACT_APP_FRACTION as string

export interface BodyAreaParamsInterface {
  title?: string;
  procedure_id?: number,
  limit: number;
  offset: number;
  page: number;
}

export class BodyAreaParams implements BodyAreaParamsInterface {
  title?: string = undefined;
  procedure_id?: number = undefined;
  limit: number = 10;
  offset: number = 0;
  page: number = 0;

  isOk() : boolean {
    return (!isEmptyString(this.title))
      || (this.procedure_id !== undefined);
  }

  extract() : any {
    let params: BodyAreaParamsInterface = {limit: this.limit, offset: this.offset, page: this.page};
    if (!isEmptyString(this.title)) { params["title"] = this.title; }
    if (this.procedure_id !== undefined) { params["procedure_id"] = this.procedure_id; }

    return params;
  }
}

export interface BodyAreaInterface {
  id?: number;
  title: string;
  price: number;
  procedure?: ProcedureInterface,
  created_at?: Date;
  modified_at?: Date;
}

export class BodyAreaModel {
  constructor(
    public id?: number,
    public title: string = "",
    private _price: number = 0,
    public procedure?: ProcedureModel,
    public createdAt?: Date,
    public modifiedAt?: Date
  ) {
  }

  get price() {
    return this._price / +FRACTION;
  }

  set price(p: number) {
    this._price = p * +FRACTION;
  }

  fromInterface(external: BodyAreaInterface) {
    this.id = external.id;
    this.title = external.title;
    this._price = external.price;
    this.createdAt = external.created_at;
    this.modifiedAt = external.modified_at;

    if (external.procedure) {
      this.procedure = procedureFromInterface(external.procedure);
    }
  }

  asInterface(): BodyAreaInterface {
    let ba = {
      "title": this.title,
      "price": this._price
    } as BodyAreaInterface;

    if(this.id) {
      ba["id"] = this.id;
    }

    if (this.procedure) {
      ba["procedure"] = this.procedure.asInterface();
    }

    return ba;
  }
}

export interface BodyAreasUpdateInterface {
  add: number[];
  del: number[];
}

export class BodyAreasUpdateModel implements BodyAreasUpdateInterface {
  add: number[] = [];
  del: number[] = [];
}

export function bodyAreaFromInterface(b: BodyAreaInterface): BodyAreaModel {
  let bodyArea = new BodyAreaModel();
  bodyArea.fromInterface(b);

  return bodyArea;
}
