import {isEmptyString} from "../generic/helpers/strings";
import {inputTextType} from "react-edit-text";


export interface ExtraField {
  label: string;
  key: string;
  type: inputTextType;
  value: any | undefined;
}

export interface ExtraFieldGroup {
  id: number;
  label: string;
  fields: ExtraField[]
}

export interface ProcedureQueryInterface {
  title?: string;
  limit: number;
  offset: number;
  page: number;
}


export class ProcedureQueryModel {
  title: string | undefined = undefined;
  limit: number = 10;
  offset: number = 0;
  page: number = 0;

  isOk() : boolean {
    return !isEmptyString(this.title);
  }

  extract() : any {
    let params: ProcedureQueryInterface = {limit: this.limit, offset: this.offset, page: this.page};
    if (!isEmptyString(this.title)) { params["title"] = this.title; }

    return params;
  }
}


export interface ProcedureInterface {
  id: number;
  title: string;
  fields?: ExtraFieldGroup;
  created_at: number;
  modified_at: number;
}

export interface ProcedureCreateInterface {
  title: string;
  fields?: ExtraFieldGroup;
}

export interface ProcedureUpdateInterface {
  id: number;
  title?: string;
  fields?: ExtraFieldGroup;
  created_at?: number;
  modified_at?: number;
}


export class ProcedureModel {
  constructor(
    public id?: number,
    public title: string = "",
    public fields?: ExtraFieldGroup,
    public createdAt?: Date,
    public modifiedAt?: Date
  ) {}

  fromInterface(external: ProcedureInterface) {
    this.id = external.id ? external.id : 0;
    this.title = external.title;
    this.createdAt = external.created_at ? new Date(external.created_at) : new Date();
    this.modifiedAt = external.modified_at ? new Date(external.modified_at) : new Date();

    if (external.fields) {
      this.fields = external.fields;
    }
  }

  asInterface(): ProcedureInterface {
    return {
      "id": this.id,
      "title": this.title,
      "fields": this.fields
    } as ProcedureInterface;
  }

  asCreateInterface(): ProcedureCreateInterface {
    let procedure = {
      "title": this.title,
    } as ProcedureCreateInterface;

    if (this.fields) {
      procedure["fields"] = this.fields;
    }

    return procedure;
  }

  asUpdateInterface(): ProcedureUpdateInterface {
    let procedure = {
      "id": this.id,
    } as ProcedureUpdateInterface;

    if (this.title) {
      procedure["title"] = this.title;
    }

    if (this.fields) {
      procedure["fields"] = this.fields;
    }

    return procedure;
  }
}

export function procedureFromInterface(p: ProcedureInterface): ProcedureModel {
  let procedure = new ProcedureModel();
  procedure.fromInterface(p);

  return procedure;
}
