import axios from 'axios'
import {AuthModel, UserModel} from './_models'

export const GET_USER_BY_ACCESSTOKEN_URL = `./auth/verify_token`
export const LOGIN_URL = `/auth/`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    access_token: token,
  })
}
