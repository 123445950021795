import axios from 'axios'
import {
  ExtraFieldGroup,
  ProcedureUpdateInterface,
  ProcedureInterface,
  ProcedureCreateInterface
} from './_models'
import {Page} from "../generic/_models";

export const CRUD_PROCEDURES_URL = `${process.env.REACT_APP_API_PATH}/procedures`
export const EXTRA_PARAMETER_TYPES_URL = `${process.env.REACT_APP_API_PATH}/extra_fields`


export function get_procedures(
  params: {} = {},
  id?: number
) {
  let url = id ? `${CRUD_PROCEDURES_URL}/${id}/` : CRUD_PROCEDURES_URL;
  return axios.get<Page<ProcedureInterface>>(`${url}/`, { params: params});
}

export function add_procedure(procedure: ProcedureCreateInterface) {
  return axios.post<ProcedureInterface>(`${CRUD_PROCEDURES_URL}/`, procedure)
}

export function update_procedure(procedure: ProcedureUpdateInterface) {
  return axios.put<ProcedureInterface>(`${CRUD_PROCEDURES_URL}/`, procedure)
}

export function delete_procedure(id: number) {
  return axios.delete<ProcedureInterface>(`${CRUD_PROCEDURES_URL}/${id}/`)
}


// ============================================================================
export function getExtraParameterTypes(
  params: {} = {},
  id?: number
) {
  let url = id ? `${EXTRA_PARAMETER_TYPES_URL}/${id}/` : EXTRA_PARAMETER_TYPES_URL;
  return axios.get<ExtraFieldGroup[]>(`${url}/`, { params: params});
}
