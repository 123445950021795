import moment from "moment";
import {isEmptyString} from "../generic/helpers/strings";

export class PersonModel {
  id: number = 0;
  first_name: string = "";
  last_name: string = "";
  phone: string = "";
  staff: boolean = false;
  birthdate: string = moment(new Date()).format('YYYY-MM-DD');
  created_at: Date = new Date();
  modified_at: Date = new Date();
}

interface PersonQueryParamsInterface {
  first_name?: string;
  last_name?: string;
  phone?: string;
  staff?: boolean,
  search?: string,
  limit: number;
  offset: number;
  page: number;
}

export class PersonQueryParamsModel {
  first_name: string | undefined = undefined;
  last_name: string | undefined = undefined;
  phone: string | undefined = undefined;
  staff: boolean | undefined = undefined;
  search: string | undefined = undefined
  limit: number = 10;
  offset: number = 0;
  page: number = 0;

  isOk() : boolean {
    return (!isEmptyString(this.last_name))
      || (!isEmptyString(this.first_name))
      || (!isEmptyString(this.phone));
  }

  extract() : any {
    let params: PersonQueryParamsInterface = {limit: this.limit, offset: this.offset, page: this.page};
    if (!isEmptyString(this.first_name)) { params["first_name"] = this.first_name; }
    if (!isEmptyString(this.last_name)) { params["last_name"] = this.last_name; }
    if (!isEmptyString(this.phone)) { params["phone"] = this.phone; }
    if (this.staff) { params["staff"] = this.staff; }
    if (!isEmptyString(this.search)) { params["search"] = this.search; }

    return params;
  }
}
