/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {addBodyArea} from "../_requests";
import {BodyAreaModel} from "../_models";
import {handleChangeNumber, handleChangeString} from "../../generic/helpers/changeHandlers";
import {
  procedureFromInterface,
  ProcedureInterface,
  ProcedureModel,
} from "../../procedures/_models";
import {Page} from "../../generic/_models";

type Props = {
  procedures: Page<ProcedureInterface>,
  refreshProcedures: Function
  callBack: Function,
}

const AddBodyAreaModal: FC<Props> = ({procedures, refreshProcedures, callBack})  => {
  let [title, setTitle] = useState("");
  let [price, setPrice] = useState(0);
  let [procedure, setProcedure] = useState<ProcedureModel>(new ProcedureModel());
  let [currentProcedureIndex, setCurrentProcedureIndex] = useState(0);

  async function createHandler(_: any) {
    let bodyArea = new BodyAreaModel()
    bodyArea.title = title;
    bodyArea.price = price;
    bodyArea.procedure = procedure;

    const resp = await addBodyArea(bodyArea.asInterface());
    await callBack();

    if (resp.status !== 202) {
      //TODO: Display message
    }

    await clearFields();
  }

  const clearFields = async () => {
    setTitle("");
    setPrice(0);
  }

  useEffect(() => {
    if (procedures.items.length > 0) {
      setProcedure(procedureFromInterface(procedures.items[currentProcedureIndex]));
    }
  }, [currentProcedureIndex, setProcedure, procedures]);

  return (
    <div className='modal fade' id='modal_add_body_area' aria-hidden='true'>
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
          </div>

          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>Add New Body Area</h1>
            </div>

            <input
              type="text"
              className="form-control mb-5"
              placeholder="Title"
              aria-label="Title"
              aria-describedby="basic-addon1"
              value={title}
              onChange={(e) => handleChangeString(e, setTitle)}
            />

            <input
              type="text"
              className="form-control mb-5"
              placeholder="Price"
              aria-label="Price"
              aria-describedby="basic-addon1"
              value={price}
              onChange={(e) => handleChangeNumber(e, setPrice)}
            />

            <div className="d-flex flex-row">
              <select
                className='form-select form-select-solid mb-5'
                data-kt-select2='true'
                data-placeholder='Select procedure'
                data-allow-clear='true'
                value={currentProcedureIndex}
                onChange={(e) => {
                  const index = Number(e.target.value);
                  setCurrentProcedureIndex(index);
                }}
              >
                {procedures.items.map((procedure, index) => {
                  return <option value={index}>{procedure.title}</option>
                })}
              </select>

              <button
                className='btn btn-icon btn-bg-light btn-active-color-primary ms-3'
                onClick={() => {refreshProcedures()}}
              >
                <KTSVG path='/media/icons/update.svg' className='svg-icon-3' />
              </button>
            </div>

            <div
              className='btn btn-light-success fw-bolder w-100 mb-8'
              data-bs-dismiss='modal'
              onClick={createHandler}
            >Add</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {AddBodyAreaModal}
