/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState, useCallback} from 'react'
import {EditText} from "react-edit-text";
import {AddExpenseModal} from "../../expenses/modals/AddExpenseModal";
import {handleChangeNumber} from "../../generic/helpers/changeHandlers";
import {DailySummaryOutInterface, DailySummaryParamsModel, DaySummaryModel} from "../_models/dailySummary";
import {getDailySummaries, updateDailySummary} from "../_requests/dailySummary";

type Props = {
  date: string
  refresher: number
}

const FRACTION: number = process.env.REACT_APP_FRACTION ? +process.env.REACT_APP_FRACTION : 100;

const ServiceDaySummary: React.FC<Props> = ({date, refresher}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let _refresher_tmp = refresher;
  let [summary, setSummary] = useState<DaySummaryModel>(new DaySummaryModel());
  let [alexandrite1, setAlexandrite1] = useState(summary.alexandrite1);
  let [alexandrite2, setAlexandrite2] = useState(summary.alexandrite2);
  let [diode1, setDiode1] = useState(summary.diode1);
  let [diode2, setDiode2] = useState(summary.diode2);
  let [carbon1, setCarbon1] = useState(summary.carbon1);
  let [carbon2, setCarbon2] = useState(summary.carbon2);
  let [rf1, setRf1] = useState(summary.rf1);
  let [rf2, setRf2] = useState(summary.rf2);

  const refreshSummary = useCallback(async () => {
    try {
      let params = new DailySummaryParamsModel();
      params.start_date = date;
      const {data, status} = await getDailySummaries(params);

      if (status >= 200 && status < 400) {
        let new_sum = new DaySummaryModel();

        if (data.items.length > 0) {
          new_sum.fromInterface(data.items[0])
        }

        setSummary(new_sum);
        setAlexandrite1(new_sum.alexandrite1);
        setAlexandrite2(new_sum.alexandrite2);
        setDiode1(new_sum.diode1);
        setDiode2(new_sum.diode2);
        setCarbon1(new_sum.carbon1);
        setCarbon2(new_sum.carbon2);
        setRf1(new_sum.rf1);
        setRf2(new_sum.rf2);
      }
    } catch (error) {
      console.log(error);
    }
  }, [date]);

  useEffect(() => {
    refreshSummary().then();
    let _x = refresher;
  }, [refreshSummary, refresher]);

  const updateHandler = async () => {
    let newSummary = {
      "date": date,
      "alexandrite1": alexandrite1,
      "alexandrite2": alexandrite2,
      "diode1": diode1,
      "diode2": diode2,
      "carbon1": carbon1,
      "carbon2": carbon2,
      "rf1": rf1,
      "rf2": rf2
    } as DailySummaryOutInterface

    const resp = await updateDailySummary(newSummary);
    let new_sum = new DaySummaryModel();
    new_sum.fromInterface(resp.data)
    setSummary(new_sum);

    if (resp.status !== 202) {
      console.log(resp.statusText);
    }

  };

  return (
    <div className='card mb-5'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#service_day_summary'
        aria-expanded='true'
        aria-controls='service_day_summary'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Summary</h3>
        </div>
      </div>

      <div id='service_day_summary' className='collapse show'>
        <div className='card-body border-top p-9'>
          <div className='summary-container gap-10'>
            <div className='py-2 flex-col'>
              <div className='d-flex flex-stack'>
                <div className='d-flex'>
                  <div className='d-flex flex-column'>
                    <div className='fs-5 text-dark text-hover-primary fw-bolder'>
                      Final laser shoot count:
                    </div>
                  </div>
                </div>
                <div className='d-flex flex-row justify-content-end gap-10'>
                  <div className='d-flex flex-row'>
                    <span>A:</span>
                    <EditText
                      type='number'
                      className='text-dark fw-bold text-hover-primary mb-1 fs-6 ms-3'
                      value={alexandrite1.toString()}
                      onChange={(e) => {handleChangeNumber(e, setAlexandrite1)}}
                      onSave={updateHandler}
                    />
                  </div>
                  <div className='d-flex flex-row'>
                    <span>D:</span>
                    <EditText
                      type='number'
                      className='text-dark fw-bold text-hover-primary mb-1 fs-6 ms-3'
                      value={diode1.toString()}
                      onChange={(e) => {handleChangeNumber(e, setDiode1)}}
                      onSave={updateHandler}
                    />
                  </div>
                  /
                  <div className='d-flex flex-row'>
                    <span>A:</span>
                    <EditText
                      type='number'
                      className='text-dark fw-bold text-hover-primary mb-1 fs-6 ms-3'
                      value={alexandrite2.toString()}
                      onChange={(e) => {handleChangeNumber(e, setAlexandrite2)}}
                      onSave={updateHandler}
                    />
                  </div>
                  <div className='d-flex flex-row'>
                    <span>D:</span>
                    <EditText
                      type='number'
                      className='text-dark fw-bold text-hover-primary mb-1 fs-6 ms-3'
                      value={diode2.toString()}
                      onChange={(e) => {handleChangeNumber(e, setDiode2)}}
                      onSave={updateHandler}
                    />
                  </div>
                </div>
              </div>

              <div className='separator separator-dashed my-5'/>

              <div className='d-flex flex-stack'>
                <div className='d-flex'>
                  <div className='d-flex flex-column'>
                    <div className='fs-5 text-dark text-hover-primary fw-bolder'>
                      Final carbon shoot count:
                    </div>
                  </div>
                </div>

                <div className='d-flex flex-row justify-content-end gap-10'>
                  <div className='d-flex flex-row'>
                    <span>C:</span>
                    <EditText
                      type='number'
                      className='text-dark fw-bold text-hover-primary mb-1 fs-6 ms-3'
                      value={carbon1.toString()}
                      onChange={(e) => {handleChangeNumber(e, setCarbon1)}}
                      onSave={updateHandler}
                    />
                  </div>
                  <div className='d-flex flex-row'>
                    <span>RF:</span>
                    <EditText
                      type='number'
                      className='text-dark fw-bold text-hover-primary mb-1 fs-6 ms-3'
                      value={rf1.toString()}
                      onChange={(e) => {handleChangeNumber(e, setRf1)}}
                      onSave={updateHandler}
                    />
                  </div>
                  /
                  <div className='d-flex flex-row'>
                    <span>C:</span>
                    <EditText
                      type='number'
                      className='text-dark fw-bold text-hover-primary mb-1 fs-6 ms-3'
                      value={carbon2.toString()}
                      onChange={(e) => {handleChangeNumber(e, setCarbon2)}}
                      onSave={updateHandler}
                    />
                  </div>
                  <div className='d-flex flex-row'>
                    <span>RF:</span>
                    <EditText
                      type='number'
                      className='text-dark fw-bold text-hover-primary mb-1 fs-6 ms-3'
                      value={rf2.toString()}
                      onChange={(e) => {handleChangeNumber(e, setRf2)}}
                      onSave={updateHandler}
                    />
                  </div>
                </div>
              </div>

              <div className='separator separator-dashed my-5'/>
            </div>

            <div className='d-flex flex-column justify-content-end flex-fill'>
              <div className='notice bg-light-info rounded border-info border border-dashed p-3 mb-4 height_35'>
                {summary.operators.map((operator) => {
                  return <>
                    <div className='d-flex flex-stack'>
                      <div className='d-flex'>
                        <div className='d-flex flex-column'>
                          <div className='fs-5 text-dark text-hover-primary fw-bolder'>
                            {`${operator.operator.first_name} ${operator.operator.last_name}`}:
                          </div>
                        </div>
                      </div>
                      <div className='d-flex justify-content-end'>
                        <div className='form-check form-check-solid form-switch'>
                          {operator.sum / (process.env.REACT_APP_FRACTION ? +process.env.REACT_APP_FRACTION : 100)}m
                        </div>
                      </div>
                    </div>
                    <div className='separator separator-dashed my-5'/>
                  </>
                })}
              </div>

              <div>
                <div className='notice bg-warning rounded border-warning border border-dashed p-3 mb-4 height_35'>
                  {summary.withdraws.withdraws.map((withdraw) => {
                    return <>
                      <div className='d-flex flex-stack'>
                        <div className='d-flex'>
                          <div className='d-flex flex-column'>
                            <div className='fs-5 text-dark text-hover-primary fw-bolder'>
                              {withdraw.description}:
                            </div>
                          </div>
                        </div>
                        <div className='d-flex justify-content-end'>
                          <div className='form-check form-check-solid form-switch'>
                            {withdraw.amount / (process.env.REACT_APP_FRACTION ? +process.env.REACT_APP_FRACTION : 100)}m
                          </div>
                        </div>
                      </div>
                      <div className='separator separator-dashed my-5'/>
                    </>
                  })}
                </div>

                <div className='notice bg-light-warning rounded border-warning border border-dashed p-3 height_35'>
                  {summary.expenses.expenses.map((expense) => {
                    return <>
                      <div className='d-flex flex-stack'>
                        <div className='d-flex'>
                          <div className='d-flex flex-column'>
                            <div className='fs-5 text-dark text-hover-primary fw-bolder'>
                              {expense.description}:
                            </div>
                          </div>
                        </div>
                        <div className='d-flex justify-content-end'>
                          <div className='form-check form-check-solid form-switch'>
                            {expense.amount / (process.env.REACT_APP_FRACTION ? +process.env.REACT_APP_FRACTION : 100)}m
                          </div>
                        </div>
                      </div>
                      <div className='separator separator-dashed my-5'/>
                    </>
                  })}
                </div>

                <button
                  type='button'
                  className='btn btn-warning btn-color-dark mt-2 w-100'
                  data-bs-toggle='modal'
                  data-bs-target='#modal_add_expense'
                >
                  <i className="bi bi-plus-circle-dotted me-2"/>
                  Add Expense
                </button>
                <AddExpenseModal callBack={refreshSummary}/>
              </div>
            </div>

          </div>
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9 pe-12'>
          <div className='text-dark fw-bolder me-2 fs-3'>Daily SUM: {
            (summary.cash_sum + summary.pos_sum + summary.c2c_sum + summary.bonus_sum) / FRACTION
          }m</div>
        </div>
      </div>
    </div>
  )
}

export {ServiceDaySummary}
