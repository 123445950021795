import {ExtraField} from "../procedures/_models";

export const buildLabelForExtraParameter = (fields: ExtraField[]) => {
  let labels: string[] = [];

  fields.map((field) => {
    labels.push(field.label);
    return true;
  });

  return labels.join(', ');
}
