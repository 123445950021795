/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useCallback, useEffect, useRef, useState} from 'react'
import Select from "react-select";
import {KTSVG} from '../../../../_metronic/helpers'
import {add_service} from "../_requests/service";
import {ServiceModel, ServiceParams} from "../_models/service";
import {handleChangeNumber, handleChangeBoolean, handleChangeString} from "../../generic/helpers/changeHandlers";
import {PersonModel, PersonQueryParamsModel} from "../../persons/_models";
import {getPersons} from "../../persons/_requests";
import {
  procedureFromInterface,
  ProcedureModel,
  ProcedureQueryModel, ProcedureInterface, ExtraFieldGroup
} from "../../procedures/_models";
import {BodyAreaModel, BodyAreaInterface, BodyAreaParams, bodyAreaFromInterface} from "../../bodyAreas/_models";
import {getBodyAreas} from "../../bodyAreas/_requests";
import {Page} from "../../generic/_models";
import {get_procedures} from "../../procedures/_requests";
import {ExtraFieldGroupComponent} from "../components/ExtraParameters";
import DateTimePicker from "react-datetime-picker";


type Option = {
  "label": string,
  "value": string
};

type Props = {
  refreshServices: Function,
  refreshSummary: Function
}

const AddServiceModal: FC<Props> = ({refreshServices, refreshSummary})  => {
  let initialRequestCount = useRef(0);
  let [clients, setClients] = useState<Page<PersonModel>>(new Page<PersonModel>());
  let [clientOptions, setClientOptions] = useState<Option[]>([]);
  let [clientTimeoutId, setClientTimeoutId] = useState<any>(0);
  let [operators, setOperators] = useState<Page<PersonModel>>(new Page<PersonModel>());
  let [operatorAsOptions, setOperatorsAsOptions] = useState<Option[]>([]);
  let [operatorsTimeoutId, setOperatorsTimeoutId] = useState<any>(0);
  let [procedures, setProcedures] = useState<Page<ProcedureInterface>>(new Page<ProcedureInterface>());
  let [proceduresAsOptions, setProceduresAsOptions] = useState<Option[]>([]);
  let [proceduresTimeoutId, setProceduresTimeoutId] = useState<any>(0);
  let [bodyAreas, setBodyAreas] = useState<Page<BodyAreaInterface>>(new Page<BodyAreaInterface>());
  let [bodyAreasAsOptions, setBodyAreasAsOptions] = useState<Option[]>([]);

  let [selectedClient, setSelectedClient] = useState<PersonModel>(new PersonModel());
  let [selectedOperator, setSelectedOperator] = useState<PersonModel>(new PersonModel());
  let [selectedProcedure, setSelectedProcedure] = useState<ProcedureModel>(new ProcedureModel());
  let [selectedBodyAreas, setSelectedBodyAreas] = useState<BodyAreaModel[]>([]);
  let [session, setSession] = useState(0);
  let [bonusCardNumber, setBonusCardNumber] = useState<string>("");
  let [payCash, setPayCash] = useState(0);
  let [payPos, setPayPos] = useState(0);
  let [payC2C, setPayC2C] = useState(0);
  let [payBonus, setPayBonus] = useState(0);
  let [correction, setCorrection] = useState(false);
  let [_extraFieldGroup, setExtraFieldGroup] = useState<ExtraFieldGroup>({
    "id": 0,
    "label": "",
    "fields": []
  });
  let [note, setNote] = useState<string>("");
  let [createdAt, setCreatedAt] = useState<Date>(new Date());

  const requestPersons = async (
    setFn: Function,
    searchTerm?: string,
    staff = false
  ) => {
    try {
      let params = new PersonQueryParamsModel();
      params.staff = staff;
      params.search = searchTerm;
      const {data} = await getPersons(params.extract());

      if (data) {
        setFn(data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const requestProcedures = async (searchTerm?: string) => {
    try {
      let params = new ProcedureQueryModel();
      params.title = searchTerm;
      const {data} = await get_procedures(params.extract());

      if (data) {
        setProcedures(data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const requestBodyAreas = async (params: BodyAreaParams) => {
    try {
      setBodyAreas(new Page<BodyAreaInterface>());
      const {data} = await getBodyAreas(params.extract());

      if (data) {
        setBodyAreas(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const startTimerForClientRequest = useCallback((newValue: string) => {
    clearTimeout(clientTimeoutId);
    const tmp_timeoutId = setTimeout(() => {
      requestPersons(setClients, newValue).then();
    }, 1000);
    setClientTimeoutId(tmp_timeoutId);
  }, [clientTimeoutId]);

  const startTimerForOperatorRequest = useCallback((newValue: string) => {
    clearTimeout(operatorsTimeoutId);
    const tmp_timeoutId = setTimeout(() => {
      requestPersons(setOperators, newValue, true).then();
    }, 1000);
    setOperatorsTimeoutId(tmp_timeoutId);
  }, [operatorsTimeoutId]);

  const startTimerForProcedureRequest = useCallback((newValue: string) => {
    clearTimeout(proceduresTimeoutId);
    const tmp_timeoutId = setTimeout(() => {
      requestProcedures(newValue).then();
    }, 1000);
    setProceduresTimeoutId(tmp_timeoutId);
  }, [proceduresTimeoutId]);

  useEffect(() => {
    const transformPersonsAsOptions = (persons: Page<PersonModel>, setFn: Function) => {
      let newOptions: any = [];

      persons.items.map((person, index) => {
        let newOption: any = {
          "label": `${person.first_name} ${person.last_name} ${person.phone}`,
          "value": index.toString()
        }

        newOptions.push(newOption);
        return true;
      });

      setFn(newOptions);
    };

    const transformProceduresAsOptions = (procedures: Page<ProcedureInterface>) => {
      let newOptions: any = [];

      procedures.items.map((procedure, index) => {
        let newOption: any = {
          "label": `${procedure.title}`,
          "value": index.toString()
        }

        newOptions.push(newOption);
        return true;
      });

      setProceduresAsOptions(newOptions);
    };

    const transformBodyAreasAsOptions = (bodyAreas: Page<BodyAreaInterface>) => {
      let newOptions: Option[] = [];

      bodyAreas.items.map((bodyArea, index) => {
        let newOption: Option = {
          "label": `${bodyArea.title}`,
          "value": index.toString()
        }

        newOptions.push(newOption);
        return true;
      });

      setBodyAreasAsOptions(newOptions);
    };

    transformPersonsAsOptions(clients, setClientOptions);
    transformPersonsAsOptions(operators, setOperatorsAsOptions);
    transformProceduresAsOptions(procedures);
    transformBodyAreasAsOptions(bodyAreas);

    if (initialRequestCount.current === 0) {
      startTimerForClientRequest("");
      startTimerForOperatorRequest("");
      startTimerForProcedureRequest("");

      initialRequestCount.current = initialRequestCount.current + 1;
    }
  }, [
    clients,
    operators,
    procedures,
    initialRequestCount,
    startTimerForClientRequest,
    startTimerForOperatorRequest,
    startTimerForProcedureRequest,
    bodyAreas,
    selectedProcedure
  ]);

  async function createHandler(_: any) {
    let s = new ServiceModel();
    s.client = selectedClient;
    s.procedure = selectedProcedure;
    s.bodyAreas = selectedBodyAreas;
    s.payCash = payCash;
    s.payPos = payPos;
    s.payC2C = payC2C;
    s.payBonus = payBonus;
    s.session = session;
    s.operator = selectedOperator;
    s.correction = correction;
    s.extra = _extraFieldGroup;
    s.createdAt = createdAt;

    if (bonusCardNumber.length > 0) {
      s.bonusCard = bonusCardNumber;
    }

    if (note.length > 0) {
      s.note = note;
    }

    const resp = await add_service(s.asCreateInterface());
    await refreshServices();

    const min = 100;
    const max = 100000000000;
    const rand = min + Math.random() * (max - min);

    refreshSummary(rand);

    if (resp.status !== 202) {
      console.log(resp.statusText);
    }

    clearFields();
  }

  const clearFields = () => {
    setSelectedClient(new PersonModel());
    setSelectedOperator(new PersonModel());
    setSelectedProcedure(new ProcedureModel());
    setPayCash(0);
    setPayPos(0);
    setPayC2C(0);
    setPayBonus(0);
  }

  const bonusCardAmountField = () => {
    if (bonusCardNumber.length === 13) {
      return <input
          type="number"
          className="form-control mb-5"
          placeholder="Pay by Bonus"
          aria-label="Pay by Bonus"
          aria-describedby="basic-addon1"
          onChange={(e) => handleChangeNumber(e, setPayBonus)}
      />
    }
  }

  return (
      <div className='modal fade' id='modal_add_service' aria-hidden='true'>
        <div className='modal-dialog mw-650px'>
          <div className='modal-content'>
            <div className='modal-header pb-0 border-0 justify-content-end'>
              <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
          </div>

          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>Add New Service</h1>
            </div>

            <Select
              className='form-control mb-3'
              data-kt-select2='true'
              data-placeholder='Select client'
              data-allow-clear='true'
              options={clientOptions}
              // @ts-ignore
              onChange={(newValue: Option) => {
                if (newValue && clients.items.length > 0) {
                  setSelectedClient(clients.items[+newValue.value]);
                }
              }}
              onInputChange={startTimerForClientRequest}
            />

            <Select
              className='form-control mb-3'
              data-kt-select2='true'
              data-placeholder='Select procedure'
              data-allow-clear='true'
              options={proceduresAsOptions}
              // @ts-ignore
              onChange={(newValue: Option) => {
                if (newValue && procedures.items.length > 0) {
                  let tmp_procedure = procedureFromInterface(procedures.items[+newValue.value]);
                  setSelectedProcedure(tmp_procedure);

                  let bodyAreaParams = new BodyAreaParams();
                  bodyAreaParams.procedure_id = tmp_procedure.id;
                  bodyAreaParams.limit = 1000;
                  requestBodyAreas(bodyAreaParams).then();
                }
              }}
              onInputChange={startTimerForProcedureRequest}
            />

            <Select
              className='form-control mb-3'
              data-kt-select2='true'
              data-placeholder='Select body areas'
              data-allow-clear='true'
              isMulti={true}
              options={bodyAreasAsOptions}
              // @ts-ignore
              onChange={(newValues: Option[]) => {
                  if (newValues.length > 0 && bodyAreas.items.length > 0) {
                    let newSelectedBodyAreas: BodyAreaModel[] = [];

                    newValues.map((value) => {
                      newSelectedBodyAreas.push(bodyAreaFromInterface(bodyAreas.items[+value.value]));
                      return null;
                    });

                    setSelectedBodyAreas(newSelectedBodyAreas);
                  }
                }
              }
            />

            <input
              type="text"
              className="form-control mb-5"
              placeholder="Bonus Card"
              aria-label="Bonus Card"
              aria-describedby="basic-addon1"
              onChange={(e) => handleChangeString(e, setBonusCardNumber)}
            />

            <input
              type="number"
              className="form-control mb-5"
              placeholder="Pay by Cash"
              aria-label="Pay by Cash"
              aria-describedby="basic-addon1"
              onChange={(e) => handleChangeNumber(e, setPayCash)}
            />

            <input
              type="number"
              className="form-control mb-5"
              placeholder="Pay by Pos Terminal"
              aria-label="Pay by Pos Terminal"
              aria-describedby="basic-addon1"
              onChange={(e) => handleChangeNumber(e, setPayPos)}
            />

            <input
              type="number"
              className="form-control mb-5"
              placeholder="Pay by C2C"
              aria-label="Pay by C2C"
              aria-describedby="basic-addon1"
              onChange={(e) => handleChangeNumber(e, setPayC2C)}
            />

            {bonusCardAmountField()}

            <input
                type="number"
                className="form-control mb-5"
                placeholder="Session"
                aria-label="Session"
                aria-describedby="basic-addon1"
                onChange={(e) => handleChangeNumber(e, setSession)}
            />

            <Select
              className='form-control mb-3'
              data-kt-select2='true'
              data-placeholder='Select client'
              data-allow-clear='true'
              options={operatorAsOptions}
              // @ts-ignore
              onChange={(newValue: Option) => {
                if (newValue && operators.items.length > 0) {
                  setSelectedOperator(operators.items[+newValue.value])
                }
              }}
              onInputChange={startTimerForOperatorRequest}
            />

            <div className="form-check mb-5">
              <input
                className="form-check-input mb-5"
                type="checkbox"
                id="flexCheckDefault"
                onChange={(e) => handleChangeBoolean(e, setCorrection)}
              />

              <label className="form-check-label" htmlFor="flexCheckDefault">
                Correction
              </label>
            </div>

            <ExtraFieldGroupComponent
              extraFieldGroup={selectedProcedure.fields}
              returnCallback={setExtraFieldGroup}
              inline={false}
            />

            <input
              type="text"
              className="form-control mb-5"
              placeholder="Note"
              aria-label="Note"
              aria-describedby="basic-addon1"
              onChange={(e) => handleChangeString(e, setNote)}
            />

            <DateTimePicker
              className="form-control mb-5"
              aria-label="Date"
              aria-describedby="basic-addon1"
              value={createdAt}
              onChange={(newDate) => {
                setCreatedAt(newDate as Date);
              }}
            />

            <div
              className='btn btn-light-success fw-bolder w-100 mb-8'
              data-bs-dismiss='modal'
              onClick={createHandler}
            >Add</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {AddServiceModal}
