/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PersonModel} from "../_models";
import {EditText} from "react-edit-text";
import {handleChangeBoolean, handleChangeString} from "../../generic/helpers/changeHandlers";
import {deletePerson, updatePerson} from "../_requests";
import moment from "moment";

type Props = {
  person: PersonModel,
  callback: Function
}

const PersonRow: React.FC<Props> = ({person, callback}) => {
  let [firstName, setFirstName] = useState(person.first_name);
  let [lastName, setLastName] = useState(person.last_name);
  let [phone, setPhone] = useState(person.phone);
  let [staff, setStaff] = useState(person.staff);
  let [birthdate, setBirthdate] = useState(person.birthdate);

  const handleSave = async () => {
    let p = new PersonModel();
    p.id = person.id;
    p.first_name = firstName;
    p.last_name = lastName;
    p.phone = phone;
    p.staff = staff;
    p.birthdate = birthdate;

    const resp = await updatePerson(p);
    await callback();

    if (resp.status !== 202) {
      console.log(resp.statusText);
    }
  };

  const handleDelete = async () => {
    const resp = await deletePerson(person.id);
    await callback();

    if (resp.status !== 202) {
      console.log(resp.statusText);
    }
  };

  const handle_staff_change = (e: { target: { checked: any; }; }) => {
    handleChangeBoolean(e, setStaff).then();
    handleSave().then();
  }

  const handleChangeDate = (e: { target: { value: string | number | Date; }; }) => {
    const newDate = moment(new Date(e.target.value)).format('YYYY-MM-DD');
    setBirthdate(newDate);
  };

  return (
    <tr>
      <td>
        <div className='d-flex align-items-center'>
          <div className='symbol symbol-50px me-5'>
            <span className='symbol-label bg-light'>
              <img
                src={toAbsoluteUrl('/media/svg/avatars/014-girl-7.svg')}
                className='h-75 align-self-end'
                alt=''
              />
            </span>
          </div>
          <div className='d-flex justify-content-start flex-row'>
            <EditText
              inline={true}
              className='text-dark fw-bold text-hover-primary mb-1 fs-6'
              value={firstName}
              showEditButton={firstName.length === 0}
              editButtonContent={<i className="bi bi-pen-fill"/>}
              inputClassName="form-control"
              editButtonProps={{className: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm"}}
              onChange={(e) => handleChangeString(e, setFirstName)}
              onSave={handleSave}
            />
          </div>
        </div>
      </td>
      <td>
        <EditText
          inline={true}
          className='text-dark fw-bold text-hover-primary mb-1 fs-6'
          value={lastName}
          showEditButton={lastName.length === 0}
          editButtonContent={<i className="bi bi-pen-fill"/>}
          inputClassName="form-control"
          editButtonProps={{className: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm"}}
          onChange={(e) => handleChangeString(e, setLastName)}
          onSave={handleSave}
        />
      </td>
      <td>
        <EditText
          inline={true}
          className='text-dark fw-bold text-hover-primary mb-1 fs-6'
          value={phone}
          showEditButton={phone.length === 0}
          editButtonContent={<i className="bi bi-pen-fill"/>}
          inputClassName="form-control"
          editButtonProps={{className: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm"}}
          onChange={(e) => handleChangeString(e, setPhone)}
          onSave={handleSave}
        />
      </td>
      <td>
        <div className="form-check mb-2">
          <input
            className="form-check-input mb-2"
            type="checkbox"
            checked={staff}
            id="flexCheckDefault"
            onChange={handle_staff_change}
          />

          <label className="form-check-label" htmlFor="flexCheckDefault">
            Staff
          </label>
        </div>
      </td>
      <td>
        <EditText
          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
          type="date"
          value={birthdate}
          onChange={handleChangeDate}
          onSave={handleSave}
        />
      </td>
      <td className='text-end'>
        <button
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
          onClick={handleDelete}
        >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </button>
      </td>
    </tr>
  )
}

export {PersonRow}
