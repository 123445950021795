export interface QueryParamsInterface {
  limit: number;
  offset: number;
  page: number;

  extract(): {};
}

export class QueryParamsModel implements QueryParamsInterface {
  limit: number = 10;
  offset: number = 0;
  page: number = 0;

  extract() : {} {
    return {limit: this.limit, offset: this.offset, page: this.page};
  }
}
