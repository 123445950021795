/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {AsideMenuItem} from './AsideMenuItem'
import {AsideMenuItemWithSub} from "./AsideMenuItemWithSub";

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/general/gen025.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
      />
      <AsideMenuItem
        to='/person'
        fontIcon='bi-people-fill mx-2'
        title={intl.formatMessage({id: 'MENU.PERSONS'})}
      />
      <AsideMenuItem
        to='/expenses'
        fontIcon='bi bi-cash-coin mx-2'
        title={intl.formatMessage({id: 'MENU.EXPENSES'})}
      />
      <AsideMenuItem
        to='/withdraws'
        fontIcon='bi bi-wallet2 mx-2'
        title={intl.formatMessage({id: 'MENU.WITHDRAWS'})}
      />
      <AsideMenuItemWithSub
        fontIcon='bi bi-tags-fill mx-2'
        to='/procedure'
        title='Procedures'
      >
        <AsideMenuItem
          to='/procedure/procedures'
          title={intl.formatMessage({id: 'MENU.PROCEDURES'})}
        />
        <AsideMenuItem
          to='/procedure/body_areas'
          title={intl.formatMessage({id: 'MENU.BODY_AREAS'})}
        />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        fontIcon='bi bi-credit-card-2-back mx-2'
        to='/bonus-card'
        title='Bonus Cards'
      >
        <AsideMenuItem
          to='/bonus-card/cards'
          title={intl.formatMessage({id: 'MENU.LIST'})}
        />
        <AsideMenuItem
          to='/bonus-card/transactions'
          title={intl.formatMessage({id: 'MENU.TRANSACTIONS'})}
        />
        <AsideMenuItem
          to='/bonus-card/types'
          title={intl.formatMessage({id: 'MENU.TYPES'})}
        />
      </AsideMenuItemWithSub>
      <AsideMenuItem
        to='/settings'
        fontIcon='bi-sliders mx-2'
        title={intl.formatMessage({id: 'MENU.SETTINGS'})}
      />
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'/>
        </div>
      </div>
    </>
  )
}
