import axios from 'axios'
import {ServiceInterface, ServiceCreateInterface, ServiceUpdateInterface} from '../_models/service'
import {Page} from "../../generic/_models";

export const CRUD_SERVICES_URL = `${process.env.REACT_APP_API_PATH}/services`


export function get_services(
  params: {} = {},
  id?: number
) {
  let url = id ? `${CRUD_SERVICES_URL}/${id}/` : CRUD_SERVICES_URL;
  return axios.get<Page<ServiceInterface>>(`${url}/`, { params: params});
}

export function add_service(service: ServiceCreateInterface) {
  return axios.post<ServiceInterface>(`${CRUD_SERVICES_URL}/`, service)
}

export function update_service(service: ServiceUpdateInterface) {
  return axios.put<ServiceInterface>(`${CRUD_SERVICES_URL}/`, service)
}

export function delete_service(id: number) {
  return axios.delete<ServiceInterface>(`${CRUD_SERVICES_URL}/${id}/`)
}
