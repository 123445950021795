/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {BodyAreaModel} from "../_models";
import {EditText} from "react-edit-text";
import {handleChangeNumber, handleChangeString} from "../../generic/helpers/changeHandlers";
import {deleteBodyArea, updateBodyArea} from "../_requests";
import {procedureFromInterface, ProcedureInterface, ProcedureModel} from "../../procedures/_models";
import {Page} from "../../generic/_models";

type Props = {
  bodyArea: BodyAreaModel,
  procedures: Page<ProcedureInterface>,
  refreshProcedures: Function
  callback: Function,
}

const BodyAreaRow: React.FC<Props> = ({bodyArea, procedures, refreshProcedures, callback}) => {
  let procedure = useRef<ProcedureModel | undefined>();
  let [title, setTitle] = useState(bodyArea.title);
  let [price, setPrice] = useState(bodyArea.price);
  let [currentProcedureIndex, setCurrentProcedureIndex] = useState(0);

  const handleSave = async () => {
    let ba = new BodyAreaModel();
    ba.id = bodyArea.id;
    ba.title = title;
    ba.price = price;

    if (procedure.current !== undefined) {
      ba.procedure = procedure.current;
    } else {
      ba.procedure = bodyArea.procedure;
    }

    const resp = await updateBodyArea(ba.asInterface());
    await callback();

    if (resp.status !== 202) {
      console.log(resp.statusText);
    }
  };

  const handleDelete = async () => {
    if (bodyArea.id) {
      const resp = await deleteBodyArea(bodyArea.id);
      await callback();

      if (resp.status !== 202) {
        console.log(resp.statusText);
      }
    }
  };

  const handleProcedureUpdate = (e: { target: { value: any; }; }) => {
    const index = Number(e.target.value);
    setCurrentProcedureIndex(index);
    procedure.current = procedureFromInterface(procedures.items[index]);
    handleSave().then();
  }

  useEffect(() => {
    const isCurrentProcedure = (procedure: ProcedureInterface) => procedure.id === bodyArea.procedure?.id;
    setCurrentProcedureIndex(procedures.items.findIndex(isCurrentProcedure));
  }, [bodyArea, procedures.items]);

  return (
    <tr>
      <td>
        <div className='d-flex align-items-center'>
          <div className='d-flex justify-content-start flex-column'>
            <EditText
              className='text-dark fw-bold text-hover-primary mb-1 fs-6'
              value={title}
              onChange={(e) => handleChangeString(e, setTitle)}
              onSave={handleSave}
            />
          </div>
        </div>
      </td>
      <td>
        <EditText
          type="number"
          className='text-dark fw-bold text-hover-primary mb-1 fs-6'
          value={price.toString()}
          onChange={(e) => handleChangeNumber(e, setPrice)}
          onSave={handleSave}
        />
      </td>
      <td>
        <div className="d-flex flex-row">
          <select
            className='form-select form-select-solid mb-2'
            data-kt-select2='true'
            data-placeholder='Select procedure'
            data-allow-clear='true'
            value={currentProcedureIndex}
            onChange={(e) => handleProcedureUpdate(e)}
          >
            {procedures.items.map((procedure, index) => {
              return <option value={index}>{procedure.title}</option>
            })}
          </select>
          <button
            className='btn btn-icon btn-bg-light btn-active-color-primary ms-3'
            onClick={() => {refreshProcedures()}}
          >
            <KTSVG path='/media/icons/update.svg' className='svg-icon-3' />
          </button>
        </div>
      </td>
      <td>
        <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
          {bodyArea.createdAt?.toString()}
        </div>
      </td>
      <td>
        <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
          {bodyArea.modifiedAt?.toString()}
        </div>
      </td>
      <td className='text-end'>
        <button
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
          onClick={handleDelete}
        >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </button>
      </td>
    </tr>
  )
}

export {BodyAreaRow}
