/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {CardModel} from "../_models";

type Props = {
  card: CardModel,
}

const CardRow: React.FC<Props> = ({card}) => {
  const re_card = new CardModel(card);

  return (
    <tr>
      <td>
        <div className='d-flex align-items-center'>
          <div className='symbol symbol-50px me-5'>
            <span className='symbol-label bg-light'>
              <img
                src={toAbsoluteUrl('/media/svg/avatars/014-girl-7.svg')}
                className='h-75 align-self-end'
                alt=''
              />
            </span>
          </div>
          <div className='d-flex justify-content-start flex-column'>
            <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
              {re_card.first_name} {re_card.last_name}
            </a>
            <span className='text-muted fw-semibold text-muted d-block fs-7'>
              {re_card.mobile_phone}
            </span>
          </div>
        </div>
      </td>
      <td>
        <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
          {re_card.get_balance()}₼
        </div>
      </td>
      <td>
        <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
          {re_card.card_number}
        </div>
      </td>
      <td>
        <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
          {re_card.issued.toString()}
        </div>
      </td>
    </tr>
  )
}

export {CardRow}
