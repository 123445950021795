import axios from 'axios'
import {ExpenseCreateInterface, ExpenseInterface, ExpenseUpdateInterface} from './_models'
import {Page} from "../generic/_models";

const CRUD_EXPENSES_URL = `${process.env.REACT_APP_API_PATH}/expenses`

export function getExpenses(
  params: {} = {},
  id?: number
) {
  let url = id ? `${CRUD_EXPENSES_URL}/${id}/` : CRUD_EXPENSES_URL;
  return axios.get<Page<ExpenseInterface>>(`${url}/`, { params: params});
}

export function addExpense(expense: ExpenseCreateInterface) {
  return axios.post<ExpenseInterface>(`${CRUD_EXPENSES_URL}/`, expense)
}

export function updateExpense(expense: ExpenseUpdateInterface) {
  return axios.put<ExpenseInterface>(`${CRUD_EXPENSES_URL}/`, expense)
}

export function deleteExpense(id: number) {
  return axios.delete<ExpenseInterface>(`${CRUD_EXPENSES_URL}/${id}/`)
}