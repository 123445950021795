/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState, useRef} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {addPerson, getPersons} from "../_requests";
import {PersonQueryParamsModel, PersonModel} from "../_models";
import {handleChangeBoolean, handleChangeString} from "../../generic/helpers/changeHandlers";
import moment from 'moment';
import {PersonMatchesTable} from "./personMatchesTable";
import {Page} from "../../generic/_models";

type Props = {
  callBack: Function,
}

const AddPersonModal: FC<Props> = ({callBack})  => {
  let [firstName, setFirstName] = useState("");
  let [lastName, setLastName] = useState("");
  let [phone, setPhone] = useState("");
  let staff = useRef(false);
  let [staffView, setStaffView] = useState(staff.current);
  let date = new Date().toISOString().substring(0,10);
  let [birthdate, setBirthdate] = useState(date);
  let [matchedPersons, setMatchedPersons] = useState<Page<PersonModel>>(new Page<PersonModel>())
  let [timeoutId, setTimeoutId] = useState<any>(0);

  const handleChangeDate = (e: { target: { value: string | number | Date; }; }) => {
    const newDate = moment(new Date(e.target.value)).format('YYYY-MM-DD');
    setBirthdate(newDate);
  };

  const requestPersons = () => {
    try {
      let requestParams = new PersonQueryParamsModel();
      requestParams.first_name = firstName;
      requestParams.last_name = lastName;
      requestParams.phone = phone;
      requestParams.staff = staff.current;

      setMatchedPersons(new Page<PersonModel>());

      if (requestParams.isOk()) {
        getPersons(requestParams.extract()).then((data) => {
          setMatchedPersons(data.data);
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  const startTimerForPersonRequest = () => {
    clearTimeout(timeoutId);
    const tmp_timeoutId = setTimeout(() => {
      requestPersons();
    }, 1000);
    setTimeoutId(tmp_timeoutId);
  };

  async function createHandler(_: any) {
    let person = new PersonModel()
    person.first_name = firstName;
    person.last_name = lastName;
    person.phone = phone;
    person.birthdate = birthdate
    person.staff = staff.current;

    const resp = await addPerson(person);
    await callBack();

    if (resp.status !== 202) {
      //TODO: Display message
    }

    await clearFields();
  }

  async function clearFields() {
    await setFirstName("");
    await setLastName("");
    await setPhone("");
    staff.current = false;
    let date = new Date().toISOString().substring(0,10);
    await setBirthdate(date);
  }

  function disableAddPerson() : boolean {
    return firstName.length === 0;
  }

  return (
    <div className='modal fade' id='modal_add_person' aria-hidden='true'>
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
          </div>

          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>Add New Person</h1>
            </div>

            <input
              type="text"
              className="form-control mb-5"
              placeholder="First Name"
              aria-label="First Name"
              aria-describedby="basic-addon1"
              value={firstName}
              onChange={(e) => {
                handleChangeString(e, setFirstName);
              }}
              onKeyUp={() => {startTimerForPersonRequest()}}
            />

            <input
              type="text"
              className="form-control mb-5"
              placeholder="Last Name"
              aria-label="Last Name"
              aria-describedby="basic-addon1"
              value={lastName}
              onChange={(e) => {
                handleChangeString(e, setLastName);
              }}
              onKeyUp={() => {startTimerForPersonRequest()}}
            />

            <input
              type="text"
              className="form-control mb-5"
              placeholder="Phone"
              aria-label="Phone"
              aria-describedby="basic-addon1"
              value={phone}
              onChange={(e) => {
                handleChangeString(e, setPhone)
              }}
              onKeyUp={() => {startTimerForPersonRequest()}}
            />

            <div className="form-check mb-5">
              <input
                className="form-check-input mb-5"
                type="checkbox"
                value=""
                id="flexCheckDefault"
                checked={staffView}
                onChange={(e) => {
                  staff.current = e.target.checked;
                  setStaffView(staff.current);
                }}
              />

              <label className="form-check-label" htmlFor="flexCheckDefault">
                Staff
              </label>
            </div>

            <input
              type="date"
              className="form-control mb-5"
              placeholder="Birthdate"
              aria-label="Birthdate"
              aria-describedby="basic-addon1"
              value={birthdate}
              onChange={handleChangeDate}
            />

            <PersonMatchesTable persons={matchedPersons.items} />

            <button
              className='btn btn-light-success fw-bolder w-100 mb-8'
              data-bs-dismiss='modal'
              onClick={createHandler}
              disabled={disableAddPerson()}
            >Add</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export {AddPersonModal}
