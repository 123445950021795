import {PersonModel} from "../../persons/_models";
import {ExpenseInterface} from "../../expenses/_models";
import {WithdrawInterface} from "../../withdraws/_models";
import {QueryParamsModel, QueryParamsInterface} from "../../generic/helpers/queryParams";


export interface DailySummaryParamsInterface extends QueryParamsInterface{
  start_date?: string;
}

export class DailySummaryParamsModel extends QueryParamsModel {
  start_date?: string;

  extract(): {} {
    let params = super.extract() as DailySummaryParamsInterface;
    if (this.start_date) { params["start_date"] = this.start_date; }

    return params;
  }
}


export class OperatorSumModel {
  operator: PersonModel = new PersonModel();
  cash: number = 0;
  pos: number = 0;
  c2c: number = 0;
  bonus: number = 0;
  sum: number = 0;
}

export interface ExpensesSumInterface {
  expenses: ExpenseInterface[];
  sum: number;
}

export class ExpensesSumModel implements ExpensesSumInterface {
  expenses: ExpenseInterface[] = [];
  sum: number = 0;
}

export interface WithdrawsSumInterface {
  withdraws: WithdrawInterface[];
  sum: number;
}

export class WithdrawsSumModel implements WithdrawsSumInterface {
  withdraws: WithdrawInterface[] = [];
  sum: number = 0;
}

export interface DailySummaryInInterface {
  id: number;
  alexandrite1: number;
  alexandrite2: number;
  diode1: number;
  diode2: number;
  carbon1: number;
  carbon2: number;
  rf1: number;
  rf2: number;
  operators: OperatorSumModel[];
  expenses: ExpensesSumInterface;
  withdraws: WithdrawsSumInterface;
  cash_sum: number;
  pos_sum: number;
  c2c_sum: number;
  bonus_sum: number;
  created_at: Date;
  modified_at: Date;
}

export interface DailySummaryOutInterface {
  date: string;
  alexandrite1: number;
  alexandrite2: number;
  diode1: number;
  diode2: number;
  carbon1: number;
  carbon2: number;
  rf1: number;
  rf2: number;
}

export class DaySummaryModel implements DailySummaryInInterface {
  id: number = 0;
  alexandrite1: number = 0;
  alexandrite2: number = 0;
  diode1: number = 0;
  diode2: number = 0;
  carbon1: number = 0;
  carbon2: number = 0;
  rf1: number = 0;
  rf2: number = 0;
  operators: OperatorSumModel[] = [];
  expenses: ExpensesSumInterface = new ExpensesSumModel();
  withdraws: WithdrawsSumInterface = new WithdrawsSumModel();
  cash_sum: number = 0;
  pos_sum: number = 0;
  c2c_sum: number = 0;
  bonus_sum: number = 0;
  created_at: Date = new Date();
  modified_at: Date = new Date();

  fromInterface(input: DailySummaryInInterface) {
    this.id = input.id;
    this.alexandrite1 = input.alexandrite1;
    this.alexandrite2 = input.alexandrite2;
    this.diode1 = input.diode1;
    this.diode2 = input.diode2;
    this.carbon1 = input.carbon1;
    this.carbon2 = input.carbon2;
    this.rf1 = input.rf1;
    this.rf2 = input.rf2;
    this.operators = input.operators;
    this.expenses = input.expenses;
    this.withdraws = input.withdraws;
    this.cash_sum = input.cash_sum;
    this.pos_sum = input.pos_sum;
    this.c2c_sum = input.c2c_sum;
    this.bonus_sum = input.bonus_sum;
    this.created_at = input.created_at;
    this.modified_at = input.modified_at;
  }

  asInterface() : DailySummaryOutInterface {
    return {
      "date": this.created_at.toISOString().split('T')[0],
      "alexandrite1": this.alexandrite1,
      "alexandrite2": this.alexandrite2,
      "diode1": this.diode1,
      "diode2": this.diode2,
      "carbon1": this.carbon1,
      "carbon2": this.carbon2,
      "rf1": this.rf1,
      "rf2": this.rf2
    } as DailySummaryOutInterface
  }
}
